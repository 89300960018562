const mocoProjects = {
  testingProject: {
    id: 945178134,
    tasks: {
      grafik: 5438695,
      task216: 5439030,
      entwicklung: 5438694,
      projektleitung: 5438693
    }
  },
  itasteTestProject: {
    id: 945322146
  },
  abwesenheitenReisezeiten: {
    id: 945324031,
    tasks: {
      urlaub: 6819272,
      krankenstand: 6819273,
      bezahlte_Abwesenheit: 6819274,
      zeitausgleich: 6819275,
      reisezeiten_Aktiv_100: 8367220,
      pflegefreistellung: 8367255,
      arztbesuch: 8367217,
      reisezeiten_Passiv_50: 8367219,
      wegzeit: 8367256
    }
  }
}

module.exports = {
  standardDay: {
    activities: [{
      description: 'default demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    },
    {
      description: 'default demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },
    {
      from: '12:30',
      to: '16:30'
    }]
  },
  minimumDay: {
    activities: [{
      description: 'default demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 6
    }],
    presences: [{
      from: '08:00',
      to: '14:00'
    }]
  },
  longerBreak: {
    activities: [{
      description: 'default demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    },
    {
      description: 'default demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },
    {
      from: '13:00',
      to: '17:00'
    }]
  },
  homeOffice: {
    activities: [{
      description: 'default demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    },
    {
      description: 'default demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00',
      isHomeOffice: true
    },
    {
      from: '12:30',
      to: '16:30',
      isHomeOffice: true
    }]
  },
  noBreak: { //Error: Länger als 6 Stunden gearbeitet, aber keine Pause (>= 30 Minuten) vorhanden
    activities: [{
      description: 'noBreak demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    },
    {
      description: 'noBreak demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '16:00'
    }]
  },
  tooShortBreak: { //Error: Länger als 6 Stunden gearbeitet, aber keine Pause (>= 30 Minuten) vorhanden
    activities: [{
      description: 'noBreak demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    },
    {
      description: 'noBreak demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },{
      from: '12:15',
      to: '16:15'
    }]
  },
  timeDifferences: { //Error: Haben und Leistungszeit unterschiedlich
    activities: [{
      description: 'timeDifferences demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    },
    {
      description: 'timeDifferences demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },
    {
      from: '12:30',
      to: '17:30'
    }]
  },
  absencesExceedLimit: { //Warning: Tage mit gebuchten Abwesenheiten sollen Tagessollarbeitszeit nicht überschreiten
    activities: [{
      description: 'absencesExceedLimit demo description1',
      project_id: mocoProjects.abwesenheitenReisezeiten.id,
      task_id: mocoProjects.abwesenheitenReisezeiten.tasks.arztbesuch,
      hours: 4
    },
    {
      description: 'absencesExceedLimit demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },
    {
      from: '12:30',
      to: '16:30'
    }]
  },
  over10h: { //Warning: 10 Stunden Haben überschritten
    activities: [{
      description: 'over10h demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 7
    },
    {
      description: 'over10h demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 4
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },
    {
      from: '12:30',
      to: '19:30'
    }]
  },
  presenceAndActivityNotRoundedToFifteenMinutes: { //Warning: Arbeitszeit & Leistungszeit nicht auf 15 Minuten gerundet 
    activities: [{
      description: 'Zeiteintrag nicht auf 15 Minuten gerundet',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 0.2
    }],
    presences: [{
      from: '08:00',
      to: '08:12'
    }]
  },
  presenceNotRoundedToFifteenMinutes: { //Warning: Arbeitszeit nicht auf 15 Minuten gerundet 
    activities: [{
      description: '15 Minuten aber nicht auf 0,15,30,45 genau',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 0.25
    }],
    presences: [{
      from: '08:14',
      to: '08:29'
    }]
  },
  over12h: { //Error: Maximum von 12 Stunden Haben überschritten
    activities: [{
      description: 'over12h demo description1',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 6
    },
    {
      description: 'over12h demo description2',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.grafik,
      hours: 7
    }],
    presences: [{
      from: '06:00',
      to: '12:00'
    },
    {
      from: '12:30',
      to: '19:30'
    }]
  },
  absencesAloneExceedLimit: { //Error: Gebuchte Abwesenheiten dürfen Tagessollarbeitszeit nicht überschreiten
    activities: [{
      description: 'absencesAloneExceedLimit demo description1',
      project_id: mocoProjects.abwesenheitenReisezeiten.id,
      task_id: mocoProjects.abwesenheitenReisezeiten.tasks.krankenstand,
      hours: 8
    }],
    presences: [{
      from: '08:00',
      to: '12:00'
    },
    {
      from: '12:30',
      to: '16:30'
    }]
  },
  activityWith0Duration: { //Warning: Leistungszeit Eintrag hat eine Dauer von 0 Stunden 
    activities: [{
      description: 'Aktivity hat keine Duration',
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 0
    }],
    presences: [],
  },
  activityWithoutDescription: { //Warning: Leistungszeit Eintrag hat keine Beschreibung
    activities: [{
      description: undefined,
      project_id: mocoProjects.testingProject.id,
      task_id: mocoProjects.testingProject.tasks.entwicklung,
      hours: 4
    }],
    presences: [{
      from: '10:30',
      to: '14:30'
    }],
  },
  holiday: {
    activities: [{
      description: 'Urlaub',
      project_id: mocoProjects.abwesenheitenReisezeiten.id,
      task_id: mocoProjects.abwesenheitenReisezeiten.tasks.urlaub,
      hours: 7.7
    }],
    presences: [{
      from: '08:00',
      to: '15:42'
    }]
  },
  empty: {
    activities: [],
    presences: []
  },
}
