<script lang="js">
  import { dateutils, basicutils } from 'seqitracker-util';

  export default {
    data() {
      return {
      }
    },

    methods: {
      calcTimeDifference: dateutils.calcTimeDifference,
      calcTimeDifferenceAsTime: dateutils.calcTimeDifferenceAsTime,
      calcTimeDifferenceAsHours: dateutils.calcTimeDifferenceAsHours,
      yearsSince: dateutils.yearsSince,

      replaceUmlaute: basicutils.replaceUmlaute,
      equalsIgnoreCase: basicutils.equalsIgnoreCase,
      leadingZero: basicutils.leadingZero,
      isNullOrEmpty: basicutils.isNullOrEmpty,
      hasValue: basicutils.hasValue,
      precisionRound: basicutils.precisionRound,
      compareFullName: basicutils.compareFullName
    },
  };
</script>