<template>
  <div>
    <h2>razzfazz.io Admin Bereich</h2>
    <v-col id="adminarea" data-cy="adminBereich" cols="12">
      <v-card>
        <v-card-title>
          Server Cache
        </v-card-title>
        <v-card-text>
          <v-expansion-panels accordion multiple hover>
            <v-expansion-panel v-for="{ header, value } of panelItems" :key="header">
              <v-expansion-panel-header>{{ header }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <pre>{{ value }}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>
            <v-col cols="12">
              <v-btn id="refreshCacheButton" type="button" @click.native="refreshCache()" :loading="cacheRefreshing"
                color="#c00000" class="white--text">Cache aktualisieren</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title>
          Import Feiertage
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="auto">
              <v-select v-model="selectedYear" :items="years" return-object></v-select>
              <v-btn id="addHolidaysButton" type="button" @click.native="addHolidays()" :loading="holidaysImporting"
                color="#c00000" class="white--text">Feiertage erneut importieren</v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex align-content-end">
            <v-col>
              <h3>Button nur einmal klicken! Prozess läuft im Hintergrund und kann über 10 Minuten dauern! </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title>
          Test Error Mail
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn id="testMailButton" @click.native="testMail()" :loading="testMailSending" color="#c00000"
                class="white--text ">Send Mail</v-btn>
            </v-col>
            <v-col align-self="center">
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-row>
          <v-col xl="4" lg="12">
            <v-card id="pushNotifications" flat>
              <v-card-title>
                Push Notifications
              </v-card-title>
              <v-col xl="12" lg="6">
                <v-card flat class="mx-10">
                  <v-tabs color="black" v-model="pushNotification.selectedReceiver" centered icons-and-text>
                    <v-tabs-slider color="#c00000"></v-tabs-slider>
                    <v-tab id="personTab">
                      Person
                      <v-icon>mdi-account </v-icon>
                    </v-tab>
                    <v-tab id="topicsTab">
                      Verfügbares Topic
                      <v-icon>mdi-bookmark-multiple</v-icon>
                    </v-tab>
                    <v-tab id="ownTopicTab">
                      Eigenes Topic
                      <v-icon>mdi-bookmark</v-icon>
                    </v-tab>
                  </v-tabs>
                  <v-divider></v-divider>
                  <v-tabs-items id="receiver" v-model="pushNotification.selectedReceiver">
                    <v-tab-item>
                      <v-card flat class="mt-5">
                        <v-select label="Person" id="selectedEmail" v-model="pushNotification.selectedEmail"
                          :items="mobileClientUsers.map((user) => user.email)"></v-select>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat class="mt-5">
                        <v-select label="Verfügbares Topic" id="selectedTopic" v-model="pushNotification.selectedTopic"
                          :items="pushNotification.topics"></v-select>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat class="mt-5">
                        <v-text-field label="Eigenes Topic" id="selectedOwnTopic"
                          v-model="pushNotification.selectedOwnTopic"></v-text-field>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-text-field id="title" dense label="Titel" v-model="pushNotification.title" outlined />
                  <v-textarea id="pushNotificationMessage" outlined label="Nachricht" name="input-7-1"
                    v-model="pushNotification.message" />
                  <v-card-actions>
                    <v-btn :loading="pushNotification.sendButtonLoading" id="sendPushNotification"
                      :disabled="isPushNotificationButtonDisabled" type="button" color="#c00000" class="white--text"
                      @click="sendPushNotification()">Notification
                      versenden</v-btn>
                  </v-card-actions>
                  <v-fade-transition>
                    <v-snackbar id="deviceTokenCopiedMessage" v-if="tokenManagement.showDeviceTokenCopiedMessage"
                      v-model="tokenManagement.showDeviceTokenCopiedMessage">
                      Device Token erfolgreich kopiert!
                      <template v-slot:action="{ attrs }">
                        <v-btn color="green" text v-bind="attrs"
                          @click="tokenManagement.showDeviceTokenCopiedMessage = false">
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-fade-transition>
                </v-card>
              </v-col>
            </v-card>
          </v-col>
          <v-divider vertical class="my-12"></v-divider>
          <v-col xl=8 lg="12">
            <v-card flat class="ml-5">
              <v-card-title>
                Angemeldete Geräte
              </v-card-title>
              <v-fade-transition v-if="tokenManagement.fetchingTokensLoading" mode="out-in">
                <v-container style="height: 400px;">
                  <v-row class="fill-height" align-content="center" justify="center">
                    <v-col class="text-subtitle-1 text-center" cols="12">
                    </v-col>
                    <v-col cols="6">
                      <v-progress-linear color="#c00000" indeterminate rounded height="6"></v-progress-linear>
                    </v-col>
                  </v-row>
                </v-container>
              </v-fade-transition>
              <v-fade-transition v-else mode="out-in">
                <v-card flat>
                  <v-row class="ma-5" no-gutters>
                    <v-col cols="auto">
                      <v-card flat max-height="400" class="overflow-y-auto custom-scrollbar">
                        <v-tabs color="black" vertical v-model="tokenManagement.selectedMobileClientUserIndex"
                          @change="tokenManagement.selectedDocumentIndex = undefined">
                          <v-tabs-slider color="#c00000"></v-tabs-slider>
                          <v-tab v-for="(user, i) in mobileClientUsers" :key="user.email"
                            :id="'user_index_' + i + '_tab'" class="justify-start">
                            <v-icon left>
                              mdi-account
                            </v-icon>
                            {{ user.email }} <v-spacer></v-spacer> {{ "("+user.tokenDocuments.length + ")" }}
                          </v-tab>
                        </v-tabs>
                      </v-card>
                    </v-col>
                    <v-col lg="8" sm="12">
                      <v-card flat height="400" class="fill-width overflow-y-auto custom-scrollbar">
                        <v-list class="pt-0">
                          <v-list-item-group v-model="tokenManagement.selectedDocumentIndex">
                            <div v-for="(tokenDocument, i) in filteredTokenDocumentsByUser" :key="i"> 
                              <v-list-item
                                :id="'user_index_' + tokenManagement.selectedMobileClientUserIndex + '_token_document_' + i">
                                <template>
                                  <v-list-item-content class="py-2 ml-5">
                                    <v-row>
                                      <v-col cols="auto" dense class="mt-2">
                                        <v-icon left>mdi-cellphone</v-icon>
                                      </v-col>
                                      <v-col cols="6" class="text-start">
                                        <v-list-item-title>
                                          {{
                                            mobileClientUsers[tokenManagement.selectedMobileClientUserIndex].email
                                          }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle wrap class="mt-1"><b>Device Token:</b>
                                          {{ tokenDocument.device_token }}
                                        </v-list-item-subtitle>
                                      </v-col>
                                    </v-row>
                                  </v-list-item-content>
                                  <v-chip v-if="tokenDocument.enabled" class="text-overline" color="green" outlined
                                    id="status_text">
                                    Aktiv
                                  </v-chip>
                                  <v-chip v-else class="text-overline" color="#c00000" outlined id="status_text">
                                    Inaktiv
                                  </v-chip>
                                  <v-list-item-action>
                                    <v-list-item-action-text>{{'registiert am ' + tokenDocument.created.format('YYYY-MM-DD HH:mm')}}</v-list-item-action-text>
                                  </v-list-item-action>
                                </template>
                              </v-list-item>
                              <v-divider v-if="i < filteredTokenDocumentsByUser.length - 1" />
                            </div>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                      <v-card-actions>
                        <v-btn id="copySelectedDeviceTokenButton" type="button" color="#c00000" class="white--text"
                          :disabled="selectedDocument == undefined" @click="copySelectedDeviceToken()">Token ins Clipboard kopieren
                        </v-btn>
                        <v-btn id="toggleNotificationsForDeviceTokenButton" type="button" color="#c00000"
                          class="white--text" :loading="tokenManagement.buttons.activateButtonLoading"
                          :disabled="selectedDocument == undefined" @click="toggleNotificationsForDeviceToken()">
                          {{ (selectedDocument != undefined) ? ((selectedDocument.enabled) ? 'Deaktivieren' : 'Aktivieren')
                          : 'Aktivieren' }}
                          <!-- Bevor die Abfrage nach enabled läuft, wird selectedDocument nach undefined geprüft -->
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn id="deleteTokenDocumentButton" type="button" color="#c00000" class="white--text mr-2"
                          :disabled="selectedDocument == undefined" :loading="tokenManagement.buttons.deleteButtonLoading"
                          @click="deleteTokenDocument()">Löschen</v-btn>
                        <v-dialog v-model="tokenManagement.buttons.deleteAllDialogVisible" persistent max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn id="deleteAllTokenDocumentsOfUserButton" type="button" color="#c00000" outlined
                              :disabled="selectedMobileClientUser != undefined && selectedMobileClientUser.tokenDocuments.length <= 0"
                              v-bind="attrs" v-on="on">
                              Alle Löschen
                            </v-btn>
                          </template>
                          <v-card id="deleteAllTokenDocumentsOfUserDialogWindow">
                            <v-card-title class="text-center justify-center">
                              Sind Sie sich sicher?
                            </v-card-title>
                            <v-card-text class="pb-0">Alle angemeldeten Geräte vom User <b>{{ selectedMobileClientUser != undefined
                            ?
                            selectedMobileClientUser.email : '' }}</b> werden abgemeldet.
                              <v-checkbox color="#c00000" v-model="tokenManagement.buttons.deleteAllExceptNewestCheckbox">
                                <template v-slot:label>
                                  <v-card-text class="ma-0 pa-0">
                                    Aktuellstes Gerät <b>nicht</b> abmelden
                                  </v-card-text>
                                </template>
                              </v-checkbox>
                            </v-card-text>
                            <v-card-actions class="pt-0">

                              <v-btn text @click="tokenManagement.buttons.deleteAllDialogVisible = false">
                                Abbrechen
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn id="deleteAllTokenDocumentsOfUserDialogButton" color="#c00000" text
                                @click="deleteAllTokenDocumentsOfUser()"
                                :loading="tokenManagement.buttons.deleteAllButtonLoading">
                                Fortfahren
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-card>
              </v-fade-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-snackbar id="errorMessage" v-if="errorHandling.showError" v-model="errorHandling.showError" bottom
      color="red accent-2">
      <v-progress-circular indeterminate color="white"> </v-progress-circular>
      {{ errorHandling.errorMessage }}
    </v-snackbar>
  </div>
</template>

<script lang="js">
import * as rzfzAdminClient from '@client/client.rzfzAdmin';
import * as fcmClient from '@client/client.fcm';
import dayjs from '@util/dayjs';

export default {
  name: 'Admin',
  data() {
    return {
      pushNotification: {
        selectedReceiver: null,
        topics: undefined,
        selectedOwnTopic: '',
        selectedTopic: '',
        selectedEmail: '',
        title: '',
        message: '',
        sendButtonLoading: false
      },

      mobileClientUsers: [],
      tokenManagement: {
        selectedMobileClientUserIndex: undefined,
        selectedDocumentIndex: undefined,
        buttons: {
          deleteAllExceptNewestCheckbox: false,
          deleteAllDialogVisible: false,
          deleteButtonLoading: false,
          deleteAllButtonLoading: false,
          activateButtonLoading: false
        },
        fetchingTokensLoading: true,
        showDeviceTokenCopiedMessage: false
      },

      cacheRefreshing: false,
      holidaysImporting: false,
      testMailSending: false,
      selectedYear: '',
      years: [],
      errorHandling: {
        showError: false,
        errorMessage: ''
      },
      panelItems: [{
          header: "Moco Users",
          value: undefined,
        },
        {
          header: "Moco Projects",
          value: undefined,
        },
        {
          header: "Jira Users",
          value: undefined,
        }
      ]
    }
  },

  created() {
    this.loadCacheData();

    const years = [];
    for (let i = 0; i < 12; i++) {
      years.push((dayjs().add(i, 'year')).year());
    }

    this.years = years;
    this.selectedYear = years[0];
  },
  mounted() {
    this.getTopics();
    this.getTokenDocuments();
  },
  computed: {
    isPushNotificationButtonDisabled: function () {
      return (this.pushNotification.title === ''
        || (this.pushNotification.selectedEmail === '' && this.pushNotification.selectedReceiver == 0)
        || (this.pushNotification.selectedTopic === '' && this.pushNotification.selectedReceiver == 1)
        || (this.pushNotification.selectedOwnTopic === '' && this.pushNotification.selectedReceiver == 2));
    },
    selectedMobileClientUser() { 
      if (this.tokenManagement.selectedMobileClientUserIndex != undefined && this.tokenManagement.selectedMobileClientUserIndex < this.mobileClientUsers.length) {
        return this.mobileClientUsers[this.tokenManagement.selectedMobileClientUserIndex];
      } else {
        return undefined;
      }
    },
    selectedDocument() { 
      if (this.selectedMobileClientUser != undefined && this.tokenManagement.selectedDocumentIndex != undefined
        && this.tokenManagement.selectedDocumentIndex < this.selectedMobileClientUser.tokenDocuments.length) {
        return this.selectedMobileClientUser.tokenDocuments[this.tokenManagement.selectedDocumentIndex];
      } else {
        return undefined;
      }
    },
    filteredTokenDocumentsByUser() {
      return this.mobileClientUsers
        .filter((user, index) => index === this.tokenManagement.selectedMobileClientUserIndex)
        .map(user => user.tokenDocuments)
        .flat();
    }
  },
  methods: {
    async refreshCache() {
      this.cacheRefreshing = true;
      await this.$draf(async () => { 
        try {
          await rzfzAdminClient.refreshCache();
          await this.loadCacheData();
          this.$root.$emit('reloadApp');
        } catch (error) {
          this.errorHandling.showError = true;
          this.errorHandling.errorMessage =  `Aktualisieren des Caches ist fehlgeschlagen.\n\n Error: ${error.message}`;
        }
      });
      this.cacheRefreshing = false;
    },

    async addHolidays() {
      this.holidaysImporting = true;
      try {
        await rzfzAdminClient.createPublicHolidays(this.selectedYear);
      } catch (error) {
        this.errorHandling.showError = true;
        this.errorHandling.errorMessage = error.message;
      }
      this.holidaysImporting = false;
    },

    async testMail() {
      this.testMailSending = true;
      try {
        await rzfzAdminClient.testMail();
      } catch (error) {
        this.errorHandling.showError = true;
        this.errorHandling.errorMessage = error.message;
      }
      this.testMailSending = false;
    },

    async loadCacheData() {
      try {
        const getMocoUsersPromise = rzfzAdminClient.getMocoUsers();
        const getMocoProjectsPromise = rzfzAdminClient.getMocoProjects();
        const getJiraUsersPromise=  rzfzAdminClient.getJiraUsers();

        const [mocoUsers, mocoProjects, jiraUsers] = await Promise.all([getMocoUsersPromise, getMocoProjectsPromise, getJiraUsersPromise]);

        this.panelItems[0].value = JSON.stringify(mocoUsers, null, 2);
        this.panelItems[1].value = JSON.stringify(mocoProjects, null, 2);
        this.panelItems[2].value = JSON.stringify(jiraUsers, null, 2);
      } catch (error) {
        this.errorHandling.showError = true;
        this.errorHandling.errorMessage =  `Abrufen des Caches fehlgeschlagen.\n\n Error: ${error.message}`;
      }
    },

    async getTopics() {
      this.pushNotification.topics = (await fcmClient.getTopics()).data;
    },

    async getTokenDocuments() {
      this.tokenManagement.fetchingTokensLoading = true;

      const tokenDocuments = (await fcmClient.getTokenDocuments()).data;
      this.mobileClientUsers = this.parseTokenDocuments(tokenDocuments); 
      this.tokenManagement.fetchingTokensLoading = false;
    },

    async deleteTokenDocument() {
      this.tokenManagement.buttons.deleteButtonLoading = true;

      await fcmClient.deleteTokenDocument(this.selectedDocument.device_token);
      await this.getTokenDocuments();
      this.tokenManagement.selectedDocumentIndex = undefined;

      this.tokenManagement.buttons.deleteButtonLoading = false;
    },

    async deleteAllTokenDocumentsOfUser() {
      this.tokenManagement.buttons.deleteAllButtonLoading = true;

      await fcmClient.deleteAllTokenDocumentsOfUser(this.selectedMobileClientUser.email, this.tokenManagement.buttons.deleteAllExceptNewestCheckbox);
      await this.getTokenDocuments();
      this.tokenManagement.selectedDocumentIndex = undefined;

      this.tokenManagement.buttons.deleteAllDialogVisible = false;
      this.tokenManagement.buttons.deleteAllButtonLoading = false;
    },

    async toggleNotificationsForDeviceToken() {
      this.tokenManagement.buttons.activateButtonLoading = true;

      await fcmClient.updateTokenDocument(this.selectedDocument.device_token, !this.selectedDocument.enabled)
      await this.getTokenDocuments();

      this.tokenManagement.buttons.activateButtonLoading = false;
    },

    copySelectedDeviceToken() {
      navigator.clipboard.writeText(this.selectedDocument.device_token);
      this.tokenManagement.showDeviceTokenCopiedMessage = true;
    },

    async sendPushNotification() {
      this.pushNotification.sendButtonLoading = true;

      let topic = undefined;
      let email = undefined;
      switch (this.pushNotification.selectedReceiver) {
        case 0: // Person
          email = this.pushNotification.selectedEmail;
          break;
        case 1: // Verfügbares Topic
          topic = this.pushNotification.selectedTopic;
          break;
        case 2: // Eigenes Topic
          topic = this.pushNotification.selectedOwnTopic;
          break;
      }
      try {
        await fcmClient.sendPushNotification(this.pushNotification.title, this.pushNotification.message, topic, email);
      } catch (error) {
        this.errorHandling.showError = true;
        this.errorHandling.errorMessage = error.message;
      }
      
      this.pushNotification.sendButtonLoading = false;
    },

    parseTokenDocuments(tokenDocuments) { 
      const mobileClientUsers = [];
      const emails = tokenDocuments.map((tokenDocument) => tokenDocument.email);
      const sortedEmails = [...new Set(emails)].sort((a, b) => a > b ? 1 : -1);
  
      sortedEmails.forEach((email) => {
        const filteredTokenDocuments = tokenDocuments.filter(tokenDocument => tokenDocument.email === email); 
        filteredTokenDocuments.forEach((filteredTokenDocument) => {
          if (filteredTokenDocument.enabled === undefined) {
            filteredTokenDocument.enabled = true;
          }
          filteredTokenDocument.created = dayjs(filteredTokenDocument.created, 'YYYY-MM-DD_HHmmss'); 
        });
        filteredTokenDocuments.sort((a, b) => a.created.isBefore(b.created) ? 1 : -1);
        mobileClientUsers.push({ email: email, tokenDocuments: filteredTokenDocuments });
      });
      return mobileClientUsers;
    }
  }
}
</script>
<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 0.15em;
  background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #000000;
}
</style>