<template>
  <v-card flat>

    <Flicker :loading="this.loading" @changeRoute="this.changeRoute"></Flicker>

    <v-row class="justify-center">
      <v-col class="my-8" cols="auto" md="10">
        <v-slide-y-transition>
          <EmployeeTable v-if="this.loading == false" :tableData="this.employeesTableData" ></EmployeeTable>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>

import * as hrClient from '@client/client.hr';
import Flicker from '@components/Flicker.vue';
import EmployeeTable from '@components/employees/EmployeeTable.vue';

export default {
  name: 'Employees',
  components: {
    Flicker,
    EmployeeTable
  },

  created() {
    this.$root.$on('startLoading', this.startLoading);
    this.$root.$on('reload', this.reloadEmployeesData);
  },

  mounted() {
    this.initialLoadEmployeesData();
  },

  beforeDestroy() {
    this.$root.$off('startLoading', this.startLoading);
    this.$root.$off('reload', this.reloadEmployeesData);
  },

  data() {
    return {
      loading: false,
      employeesTableData: []
    }
  },

  computed: {
    showLoadingOverlay: function () {
      return this.loading && this.initialLoad == false;
    },

    displayedDate: function () { // TODO eventuell können wir das irgendwie global ins Vue geben? Siehe main.js
      return this.$store.state.displayedMonth;
    },
  },

  methods: {
    startLoading() {
      this.loading = true;
    },

    async initialLoadEmployeesData() {
      this.initialLoad = true;
      await this.loadEmployeesData();
      this.initialLoad = false;
    },

    async loadEmployeesData() {
      this.loading = true;

      const employeesData = await this.$draf(async () => {
        return await hrClient.getEmployeesData(this.displayedDate.year(), this.displayedDate.isoMonth());
      });
      
      this.employeesTableData = employeesData;
      this.loading = false;
    },

    async reloadEmployeesData() {
      await hrClient.clearCache();
      await this.loadEmployeesData();
    },

    changeRoute(displayedDate) { // TODO überlegen, ob wir das nicht doch in den flicker geben können. alle "changeRoute" methoden sind halt sehr ähnlich. Aber nicht in RZFZ-803 ;)
      this.$router.push({
        name: this.$route.name,
        params: {
          year: displayedDate.year(),
          month: displayedDate.isoMonth()
        }
      });
    },
  },

  watch: {
    displayedDate: function () {
      this.loadEmployeesData();
    }
  }
}
</script>
