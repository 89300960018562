import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/hr`, { exclude: { paths: ['/users'] } });

const clearCache = async function () {
  await axiosInstance.cache.clear();
}

const getUsers = async function () {
  return (await axiosInstance.get('/users')).data;
}

const getTeams = async function () {
  return (await axiosInstance.get('/teams')).data;
}

const getInternalProjects = async function () {
  return (await axiosInstance.get('/internalProjects')).data;
}

const getEmployeesData = async function(year, month) {
  return (await axiosInstance.get(`/employees/${year}/${month}`)).data;
}

const createUser = async function(newUserObject) {
  await axiosInstance.post('/users', newUserObject);
}

const updateUser = async function(userID, updatedUserObject) {
  await axiosInstance.put(`/users/${userID}`, updatedUserObject);
}

const endUserEmployment = async function(userID, endDate, exemptFrom) {
  await axiosInstance.put(`/endUserEmployment/${userID}`, {endDate, exemptFrom});
}


export {
  clearCache,
  createUser,
  getUsers,
  getTeams,
  getInternalProjects,
  getEmployeesData,
  updateUser,
  endUserEmployment
}

