import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/fcm`, { exclude: { paths: ['/tokens'] } });

const getTokenDocuments = async function () {
  return await axiosInstance.get('/tokens');
}

const getTopics = async function () {
  return await axiosInstance.get('/topics');
}

const sendPushNotification = async function (title, message, topic, email) {
  const data = {
    title: title,
    message: message,
    topic: topic,
    email: email,
  }
  return await axiosInstance.post('/send', data);
}

const updateTokenDocument = async function (token, enabled) {
  return await axiosInstance.put('/updateTokenDocument', { token: token, enabled: enabled });
}

const deleteTokenDocument = async function (token) {
  return await axiosInstance.delete('/deleteTokenDocument', { params: { token: token } } );
}

const deleteAllTokenDocumentsOfUser = async function (email, exceptNewest) {
  return await axiosInstance.delete('/deleteAllTokenDocumentsOfUser', { params: { email: email, exceptNewest: exceptNewest } } );
}

export {
  sendPushNotification,
  getTopics,
  getTokenDocuments,
  deleteTokenDocument,
  deleteAllTokenDocumentsOfUser,
  updateTokenDocument
}