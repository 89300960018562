<template>
  <v-card>
    <v-data-table id="employeesDataTable" :headers="this.headers" :items="this.parsedTableData" :item-class="rowBackground" item-key="mocoUserID"
      disable-pagination hide-default-footer show-expand :expanded.sync="expandedRows">

      <template v-slot:item.data-table-expand="{ item }">
        <td @click="toggleExpand(item)" class="pa-0 pl-1">
          <v-btn icon>
            <v-icon>{{ expandedRows.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:item.fullname="{ item }">
        <td @click="toggleExpand(item)" class="pl-1">
          <v-avatar class="my-2 mr-2" size="40">
            <img alt="Avatar" :style="item.isStillEmployed ? '' : 'opacity: 0.5;'"
              :src="item.avatar_url ? item.avatar_url : require('../../assets/avatar_placeholder.png')">
          </v-avatar>
          <label :style="item.isStillEmployed ? '' : 'opacity: 0.5;'">
            {{ item.fullname.firstname + ' ' + item.fullname.lastname }}
          </label>
        </td>
      </template>
      <template v-slot:item.stdSaldo="{ item }">
        <td @click="toggleExpand(item)">
          {{ item.stdSaldo }}
        </td>
      </template>
      <template v-slot:item.gzk="{ item }">
        <td @click="toggleExpand(item)">
          {{ item.gzk }}
        </td>
      </template>
      <template v-slot:item.urlaub="{ item }">
        <td @click="toggleExpand(item)">
          {{ item.urlaub }}
        </td>
      </template>

      <template v-slot:item.status="{ item }">
        <v-btn id="goToMonatsabschluss" width="100%" class="text-overline" :to="getMonatsabschlussRoute(item)"
          :disabled="item.status == global.monthClosing.status.open" :color="getMonatsabschlussStatusColor(item.status)"
          outlined elevation="0" target="_blank">
          <strong class="ma-0 pa-0">{{ item.status }}</strong>
        </v-btn>
      </template>

      <template v-slot:item.impersonate="{ item }">
        <v-btn v-if="$store.getters.staticUserData.user.id != item.id" id="impersonateUser" width="100%" class="text-overline" 
          :to="impersonate(item)" :disabled="item.isStillEmployed != true"
          outlined elevation="0" target="_blank">
          <v-icon>mdi-account-arrow-right</v-icon>
        </v-btn>
      </template>

      <!-- expanded item -->
      <template v-slot:expanded-item="{ headers, item }">
        <td id="employeeExtraMasterData" :colspan="headers.length">
          <v-card flat class="my-3">

            <v-row no-gutters>
              <v-col class="mx-5">
                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Vorname </b></div>
                  </v-col>

                  <v-col class="custom-vertical-center">
                    <div v-if="item.fullname.firstname">{{ item.fullname.firstname }}</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Nachname </b></div>
                  </v-col>

                  <v-col class="custom-vertical-center">
                    <div v-if="item.fullname.lastname" style="font-size: 14px !important;">{{
                      item.fullname.lastname }}</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Team </b></div>
                  </v-col>
                  <v-col class="custom-vertical-center">
                    <div v-if="item.team">{{ item.team }}</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Personalnummer </b></div>
                  </v-col>
                  <v-col class="custom-vertical-center">
                    <div v-if="item.personnelNumber">{{ item.personnelNumber }}</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Adresse </b></div>
                  </v-col>
                  <v-col class="custom-vertical-center">
                    <div v-if="item.address">{{ item.address }}</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="mx-5">
                <v-row no-gutters>
                  <v-col cols="7">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Eintrittsdatum</b></div>
                  </v-col>

                  <v-col class="custom-vertical-center">
                    <div v-if="item.employedSince">{{ formatDate(item.employedSince) }}&nbsp; ({{ yearsSince(item.employedSince) }} Jahre)</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <section v-if="item.employedUntil">
                  <v-row no-gutters style="color: red">
                    <v-col cols="7">
                      <div class="text-overline" style="font-size: 13px !important;"><b>Ende Dienstverhältnis</b></div>
                    </v-col>

                    <v-col class="custom-vertical-center">
                      <div>{{ formatDate(item.employedUntil) }}</div>
                    </v-col>
                  </v-row>

                  <v-divider class="my-1"></v-divider>
                </section>

                <v-row no-gutters>
                  <v-col cols="7">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Geburtstag</b></div>
                  </v-col>

                  <v-col class="custom-vertical-center">
                    <div v-if="item.birthday" style="font-size: 14px !important;">{{ formatDate(item.birthday) }}&nbsp; ({{ yearsSince(item.birthday) }} Jahre)</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <v-row no-gutters>
                  <v-col cols="7">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Überstundenpauschale </b></div>
                  </v-col>

                  <v-col class="custom-vertical-center">
                    <div v-if="item.overTimeFlat != undefined">{{ item.overTimeFlat }} h</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>

                <v-divider class="my-1"></v-divider>

                <v-row no-gutters>
                  <v-col cols="7">
                    <div class="text-overline" style="font-size: 13px !important;"><b>Monatliche GZK Korrektur </b></div>
                  </v-col>

                  <v-col class="custom-vertical-center">
                    <div v-if="item.monthlyFlexTimeCorrection != undefined">{{ item.monthlyFlexTimeCorrection }} h</div>
                    <div v-else>-</div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="auto" v-if="userUpdateEnabled && item.isStillEmployed" class="align-center mb-12">
                <UpdateEmployee :employeeToUpdate="item"> </UpdateEmployee>
              </v-col>
            </v-row>
          </v-card>
        </td>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import UpdateEmployee from '@components/HR/UpdateEmployee.vue';

export default {
  name: 'EmployeeTable',
  props: ['tableData'],
  components: {
    UpdateEmployee
  },

  data() {
    return {
      headers: [
        { text: '', value: 'data-table-expand' },
        { text: 'Mitarbeiter', value: 'fullname', sort: (user1, user2) => this.compareFullName(user1.firstname, user1.lastname, user2.firstname, user2.lastname) },
        { text: 'Stunden Saldo (h)', value: 'stdSaldo' },
        { text: 'Gleitzeitkonto (h)', value: 'gzk' },
        { text: 'Urlaub (Tage)', value: 'urlaub' },
        { text: 'Monatsabschluss', value: 'status' },
        { text: 'Impersonate', value: 'impersonate', sortable: false }
      ],

      expandedRows: [],
      parsedTableData: undefined
    }
  },

  mounted() {
    const parsedEmployeeData = [];
    this.tableData.forEach((user) => {
      if (user.userData.masterData?.hiddenFromHrView == true) {
        return;
      }

      parsedEmployeeData.push({
        mocoUserID: user.userData.mocoUserID,
        fullname: {
          firstname: user.userData.firstname,
          lastname: user.userData.lastname,
        },
        
        id: user.userData.mocoUserID,
        email: user.userData.email,
        avatar_url: user.userData.avatar_url,
        isStillEmployed: user.userData.isStillEmployed,
        hasJiraUser: user.jiraUserData?.id,
        team: user.userData.team,

        stdSaldo: user.monthData.hours.totalDiff,
        gzk: user.monthData.hours.flexTimeBalance,
        urlaub: user.monthData.holiday.atEndOfMonth,
        status: user.monthData.closing.status,

        personnelNumber: user.userData.masterData?.personnelNumber,
        birthday: user.userData.masterData?.birthday,
        address: user.userData.masterData?.address,
        employedSince: user.userData.masterData?.employedSince,
        employedUntil: user.userData.masterData?.employedUntil,
        overTimeFlat: user.userData.masterData?.overTimeFlat,
        monthlyFlexTimeCorrection: user.userData.masterData?.monthlyFlexTimeCorrection
      });
    });

    parsedEmployeeData.sort((a, b) => {
      const sameBucket = (a.status === global.monthClosing.status.open && b.status === global.monthClosing.status.open)
        || (a.status !== global.monthClosing.status.open && b.status !== global.monthClosing.status.open);

      if (sameBucket) {
        return this.compareFullName(a.fullname.firstname, a.fullname.lastname, b.fullname.firstname, b.fullname.lastname)
      } else {
        return a.status === global.monthClosing.status.open ? 1 : -1;
      }
    });

    this.parsedTableData = parsedEmployeeData;
  },

  computed: {
    displayedDate: function () {
      return this.$store.state.displayedMonth;
    },

    userUpdateEnabled: function () {
     return this.$store.getters.staticUserData.user.roles.isHR && this.$route.name == 'HREmployees';
    }
  },

  methods: {
    toggleExpand(row) {
      if (this.expandedRows.includes(row)) {
        // wieso includes geht aber indexOf nicht, versteh ich nicht...
        this.expandedRows = this.expandedRows.filter(expandedRow => expandedRow.personnelNumber != row.personnelNumber);
      } else {
        this.expandedRows.push(row);
      }
    },

    getMonatsabschlussRoute(user) {
      return {
        name: global.ROUTES.monatsabschluss.name,
        params: {
          username: user.email.replace('@seqis.com', ''),
          year: this.displayedDate.year(),
          month: this.displayedDate.isoMonth()
        }
      };
    },

    getMonatsabschlussStatusColor(status) {
      switch (status) {
        case global.monthClosing.status.approved:
          return 'green lighten-1';
        case global.monthClosing.status.open:
          return 'black'; // nicht notwendig, button ist disabled!
        case global.monthClosing.status.waitingForTL:
          return 'orange lighten-1';
        case global.monthClosing.status.waitingForHR:
          return 'orange lighten-1';
        case global.monthClosing.status.rejected:
          return 'red lighten-2';
      }
    },

    impersonate(user) {
      return {
        name: global.ROUTES.monatsansicht.name,
        params: {
          year: this.displayedDate.year(),
          month: this.displayedDate.isoMonth()
        },
        query: {
          impersonation: user.id
        }
      };
    },

    rowBackground(item) {
      return item.employedUntil ? 'red lighten-2' : '';
    }
  }
}
</script>
<style lang="scss">
tr>td,
tr>td>label {
  cursor: pointer
}

tr>td:last-of-type {
  cursor: default
}

.align-center {
  display: flex;
  align-items: center;
}
.custom-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
} 
</style>