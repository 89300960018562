<template lang="html">
  <section class="QA">
    <v-row dense>
      <v-col id="testarea" data-cy="testBereich" cols="12">
        <v-card>
          <v-card-title color="orange accent-1">
            Testdaten
          </v-card-title>
          <v-card-text>
            <v-col cols="2">
              <v-menu ref="menu"
                v-model="menu" :close-on-content-click="false"
                transition="scale-transition" offset-y ax-width="290px" min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date" readonly
                    label="Zu manipulierendes Monat" prepend-icon="mdi-calendar"
                    v-bind="attrs" v-on="on"
                  />
                </template>
                <v-date-picker v-model="date"
                  type="month" no-title scrollable
                  @input="menu = false"
                />
              </v-menu>
            </v-col>
            <pre>{{logContainer.log}}</pre>
          </v-card-text>
          <v-card-actions class="pb-8">
            <v-btn type="button" @click.native="deleteAllEntries()" :disabled="qa.creatingTestMonth" color="#c00000" class="white--text">Alle Monatsdaten löschen</v-btn>
            <v-btn type="button" @click.native="createTestMonth()" :disabled="qa.creatingTestMonth" color="#c00000" class="white--text">Test-Monat erstellen</v-btn>
            <v-btn type="button" @click.native="createFullMonthWithoutErrors()" :disabled="qa.creatingTestMonth" color="#c00000" class="white--text">Fehlerfreies Monat erstellen</v-btn>
            <v-btn type="button" @click.native="createTestDataWithoutErrors()" :disabled="qa.creatingTestMonth" color="#c00000" class="white--text">Fehlerfreies Monat erstellen (bis heute)</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col id="testarea" data-cy="testBereich" cols="12">
        <v-card>
          <v-card-title color="orange accent-1">
            Monatsabschluss
          </v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="auto">
                <v-btn type="button" @click.native="deleteMonatsabschluss()" :disabled="qa.deletingMonatsabschluss" color="#c00000" class="white--text">Monatsabschluss löschen</v-btn>
              </v-col>
              <v-col cols="2">
                <v-text-field :label="'Issue Key (Prefix: ' + global.JIRA_PROJECT_KEY + ')'" v-model="issueKey" dense></v-text-field>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col id="testarea" data-cy="testBereich" cols="12">
        <v-card>
          <v-card-title color="orange accent-1">
            Rollen
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="auto" class="chip">
                <v-chip id="glRoleButton" @click.native="roles.GL = !roles.GL" :color="getChipColor(roles.GL)" class="white--text">Geschäftsleitung</v-chip>
              </v-col>
              <v-col cols="auto" class="chip">
                <v-chip id="tlRoleButton" @click.native="roles.TL = !roles.TL" :color="getChipColor(roles.TL)" class="white--text">Teamlead</v-chip>
              </v-col>
              <v-col cols="auto" class="chip">
                <v-chip id="adminRoleButton" @click.native="roles['razzfazz-admin'] = !roles['razzfazz-admin']" :color="getChipColor(roles['razzfazz-admin'])" class="white--text">Razzfazz Admin</v-chip>
              </v-col>
              <v-col cols="auto" class="chip">
                <v-chip id="hrRoleButton" @click.native="roles.HR = !roles.HR" :color="getChipColor(roles.HR)" class="white--text">Human Resources</v-chip>
              </v-col>
              <v-col cols="auto" class="chip">
                <v-chip id="faRoleButton" @click.native="roles.FA = !roles.FA" :color="getChipColor(roles.FA)" class="white--text">Finance</v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn id="roleSaveButton" type="button" @click.native="saveRoles()" :loading="savingRoles" color="#c00000" class="white--text">Rollen speichern</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script lang="js">
  import dayjs from '@util/dayjs';

  import * as testDataGenerator from '@test/testDataGenerator/testDataGenerator';
  import * as dayEntryTypes from '@test/testDataGenerator/testDataPresets';

  import * as qaClient from '@client/client.qa';
  import * as sqtClient from '@client/client.sqt';
  import * as mocoClient from '@client/client.moco';

  export default {
    name: 'QA',
    data() {
      return {
        qa: {
          creatingTestMonth: false,
          deletingMonatsabschluss: false
        },
        date: dayjs().format('YYYY-MM'),
        menu: false,
        issueKey: undefined,
        logContainer: { log: ''},
        roles: { // TODO check, ob das noch hinhaut
          GL: this.$store.getters.staticUserData.user.roles.isGL,
          HR: this.$store.getters.staticUserData.user.roles.isHR,
          FA: this.$store.getters.staticUserData.user.roles.isFA,
          'razzfazz-admin': this.$store.getters.staticUserData.user.roles.isRazzfazz,
          TL: this.$store.getters.staticUserData.user.roles.isTL,
        },
        savingRoles: false,
      }
    },

    computed: {
      startOfMonth: function() {
        return dayjs(this.date).startOf('month')
      },
      endOfMonth: function() {
        return dayjs(this.date).endOf('month')
      }
    },

    methods: {
      async deleteAllEntries() {
        this.qa.creatingTestMonth = true;
        this.$store.dispatch('clearError');
        this.logContainer.log = '';

        try {
          await testDataGenerator.deleteAllEntries(this.startOfMonth, this.endOfMonth, this.logContainer);
        } catch (e) {
          console.log(e);
          console.log(e.response);
          this.$store.dispatch('setError', 'Löschen der Monatsdaten fehlgeschlagen - bisl warten und dann einfach nochmal probieren :) : ' + e.response?.data);
        }
          
        await Promise.all([sqtClient.clearCache(), mocoClient.clearCache()]);
        
        await this.$store.dispatch('reloadStaticUserData');
        this.$root.$emit('reloadStaticUserData');
        
        this.logContainer.log += 'FINISH\r\n';
        this.qa.creatingTestMonth = false;
      },

      async createTestDataWithoutErrors(endDate) {
        this.qa.creatingTestMonth = true;
        this.$store.dispatch('clearError');
        this.logContainer.log = '';
  
        try {
          await testDataGenerator.createDays(this.startOfMonth, endDate ?? dayjs(), dayEntryTypes.standardDay, {deleteFirst: false}, this.logContainer);
        } catch (e) {
          console.log(e);
          console.log(e.response);
          this.$store.dispatch('setError', 'Anlage der Monatsdaten fehlgeschlagen - bisl warten und dann einfach nochmal probieren :) : ' + e.response?.data);
        }
          
        await Promise.all([sqtClient.clearCache(), mocoClient.clearCache()]);
        
        await this.$store.dispatch('reloadStaticUserData');
        this.$root.$emit('reloadStaticUserData');
  
        this.logContainer.log += 'FINISH\r\n';
        this.qa.creatingTestMonth = false;
      
      },

      async createFullMonthWithoutErrors() {
        await this.createTestDataWithoutErrors(this.endOfMonth);
      },

      async createTestMonth() {
        this.qa.creatingTestMonth = true;
        this.$store.dispatch('clearError');
        this.logContainer.log = '';

        try {
          await testDataGenerator.createTestMonth(this.startOfMonth, this.endOfMonth, this.logContainer);
        } catch (e) {
          console.log(e);
          console.log(e.response);
          this.$store.dispatch('setError', 'Anlage der Monatsdaten fehlgeschlagen - bisl warten und dann einfach nochmal probieren :) : ' + e.response?.data);
        }
          
        await Promise.all([sqtClient.clearCache(), mocoClient.clearCache()]);
        
        await this.$store.dispatch('reloadStaticUserData');
        this.$root.$emit('reloadStaticUserData');
        
        this.logContainer.log += 'FINISH\r\n';
        this.qa.creatingTestMonth = false;
      }, 

      async deleteMonatsabschluss() {
        this.$store.dispatch('clearError');
        if (this.issueKey.includes(global.JIRA_PROJECT_KEY) == false) {
          this.$store.dispatch('setError', `Der Issue Key muss mit "${global.JIRA_PROJECT_KEY}" beginnen!`);
          return;
        }
        this.qa.deletingMonatsabschluss = true;

        try {
          await qaClient.deleteMonatsabschluss(this.issueKey);
        } catch (e) {
          console.log(e);
          console.log(e.response);
          this.$store.dispatch('setError', 'Löschen vom Monatsabschluss fehlgeschlagen: ' + e.response?.data);
        }
        await this.$root.$emit('reloadApp');
        this.qa.deletingMonatsabschluss = false;
      },

      async saveRoles() {
        this.savingRoles = true;
        const roles = Object.entries(this.roles).map(([roleName, roleValue]) => roleValue == true ? roleName : undefined).filter(role => role); // remove empty array indizes
        try {
          await qaClient.saveRoles(roles);
          
          await Promise.all([sqtClient.clearCache(), mocoClient.clearCache()]);
          
          await this.$store.dispatch('reloadStaticUserData');
          this.$root.$emit('reloadStaticUserData');
        } catch (e) {
          console.error(e);
          console.error(e.response);
          this.$store.dispatch('setError', 'Ändern der Rollen fehlgeschlagen: ' + e.response?.data);
        }
        
        this.savingRoles = false;
      },

      getChipColor(hasRole) {
        return hasRole ? '#c00000':'lightgrey';
      }
    }
  }
</script>
  
<style lang="scss">
#testarea .chip span {
  cursor: pointer;
}
</style>