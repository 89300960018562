import Vue from 'vue';
import VueRouter from 'vue-router';
import dayjs from '@util/dayjs';

import store from '@store/store';

import UserMonth from '@/views/UserMonth.vue';
import Monatsabschluss from '@/views/Monatsabschluss.vue';
import ProjectMonth from '@/views/ProjectMonth.vue';
import Settings from '@/views/Settings.vue';
import QA from '@/views/QA.vue';
import HR from '@/views/HR.vue';
import GL from '@/views/GL.vue';
import Admin from '@/views/Admin.vue';
import Teamlead from '@/views/Teamlead.vue';
import CreateEmployee from '@/components/HR/CreateEmployee.vue';
import Employees from '@/components/employees/Employees.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: global.ROUTES.root.path,
    redirect: `${global.ROUTES.monatsansicht.path}/${dayjs().year()}/${dayjs().isoMonth()}`
  },
  // Monatsansicht
  {
    path: global.ROUTES.aktuellermonat.path,
    redirect: `${global.ROUTES.monatsansicht.path}/${dayjs().year()}/${dayjs().isoMonth()}`,
  },
  {
    path: global.ROUTES.monatsansicht.path,
    component: UserMonth,
    redirect: () => `${global.ROUTES.monatsansicht.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`
  },
  {
    path: `${global.ROUTES.monatsansicht.path}/:year/:month`,
    component: UserMonth,
    name: global.ROUTES.monatsansicht.name,
    meta: {
      key: () => { return 'usermonth'; }
    }
  },

  // Monatsabschluss
  {
    path: global.ROUTES.monatsabschluss.path,
    component: Monatsabschluss,
    meta: {
      key: (route) => { return route.fullPath; }
    }
  },
  {
    path: `${global.ROUTES.monatsabschluss.path}/:username/:year/:month`,
    component: Monatsabschluss,
    name: global.ROUTES.monatsabschluss.name,
    meta: {
      key: (route) => { return route.fullPath; },
    }
  },

  // Projektabschluss
  {
    path: global.ROUTES.projekt.path,
    redirect: () => `${global.ROUTES.projekt.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`
  },
  {
    path: `${global.ROUTES.projekt.path}/:year/:month`,
    component: ProjectMonth,
    name: global.ROUTES.projekt.name,
    meta: {
      key: (route) => { return route.query.projectID; }
    }
  },

  // Einstellungen
  {
    path: global.ROUTES.einstellungen.path,
    component: Settings,
    beforeEnter: (to, from, next) => {
      if (!global.impersonationID) {
        next();
      } else {
        next({ path: global.ROUTES.aktuellermonat.path });
      }
    },
    meta: {
      key: () => { return 'settings'; }
    }
  },

  // QA
  {
    path: global.ROUTES.qa.path,
    component: QA,
    beforeEnter: (to, from, next) => {
      if (process.env.VUE_APP_LOCAL_STORAGE_ENV == 'staging' && !global.impersonationID) {
        next();
      } else {
        next({ path: global.ROUTES.aktuellermonat.path });
      }
    },
    meta: {
      key: () => { return 'qa'; }
    }
  },

  // Teamlead
  {
    path: global.ROUTES.teamlead.path,
    component: Teamlead,
    redirect: () => `${global.ROUTES.teamlead.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`
  },
  {
    path: `${global.ROUTES.teamlead.path}/:year/:month`,
    component: Teamlead,
    name: global.ROUTES.teamlead.name,
    beforeEnter: (to, from, next) => {
      if (store.getters.staticUserData.user?.roles.isTL && !global.impersonationID) {
        next();
      } else {
        next({ path: global.ROUTES.aktuellermonat.path });
      }
    },
    meta: {
      key: () => { return 'teamlead' }
    }
  },

  // HR
  {
    path: global.ROUTES.hr.path,
    component: HR,
    beforeEnter: (to, from, next) => {
      if (store.getters.staticUserData.user?.roles.isHR && !global.impersonationID) {
        next();
      } else {
        next({ path: global.ROUTES.aktuellermonat.path });
      }
    },
    redirect: () => `${global.ROUTES.hr.path}${global.ROUTES.hr.employees.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`,
    children: [
      {
        path: global.ROUTES.hr.employees.path.substring(1),
        component: Employees,
        redirect: () => `${global.ROUTES.hr.path}${global.ROUTES.hr.employees.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`
      },
      {
        path: global.ROUTES.hr.employees.path.substring(1)+'/:year/:month',
        component: Employees,
        name: global.ROUTES.hr.employees.name,
        meta: {
          key: (route) => { return route.fullPath; },
        }
      },
      {
        path: global.ROUTES.hr.createEmployee.path.substring(1),
        component: CreateEmployee,
        name: global.ROUTES.hr.createEmployee.name,
        meta: {
          key: (route) => { return route.fullPath; },
        }
      },
    ]
  },

  // GL
  {
    path: global.ROUTES.gl.path,
    component: GL,
    beforeEnter: (to, from, next) => {
      if (store.getters.staticUserData.user?.roles.isGL && !global.impersonationID) {
        next();
      } else {
        next({ path: global.ROUTES.aktuellermonat.path });
      }
    },
    redirect: () => `${global.ROUTES.gl.path}${global.ROUTES.gl.employees.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`,
    children: [
      {
        path: global.ROUTES.gl.employees.path.substring(1),
        component: Employees,
        redirect: () => `${global.ROUTES.gl.path}${global.ROUTES.gl.employees.path}/${store.state.displayedMonth.year()}/${store.state.displayedMonth.isoMonth()}`
      },
      {
        path: global.ROUTES.gl.employees.path.substring(1)+'/:year/:month',
        component: Employees,
        name: global.ROUTES.gl.employees.name,
        meta: {
          key: (route) => { return route.fullPath; }
        }
      }
    ]
  },

  {
    path: global.ROUTES.admin.path,
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (store.getters.staticUserData.user?.roles.isRazzfazz) {
        next();
      } else {
        next({ path: global.ROUTES.aktuellermonat.path });
      }
    },
    meta: {
      key: () => { return 'admin'; }
    }
  },

  {
    path: "*", //redirect if current route does not exist
    redirect: global.ROUTES.aktuellermonat.path
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit('setPrevRoute', from);
  if (global.impersonationID && !to.query.impersonation) {
    next({ path: to.path, query: { ...to.query, impersonation: global.impersonationID }}); // wirft error in console, ist "OK" -> https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.params?.year && to.params?.month) {
    store.commit('changeDisplayMonth', dayjs.iso({ year: to.params.year, month: to.params.month, day: 1 }));
  }
});

export default router