<script lang="js">
  const statusColors = {
    error: 'red accent-2',
    warning: 'orange lighten-2',
    ok: 'green lighten-3',
    future: 'brown lighten-5'
  };
  const backgroundColors = {
    default: 'white',
    weekend: 'blue-grey lighten-4',
    publicHoliday: 'light-blue lighten-4',
    userHoliday: 'cyan lighten-4',
    error: 'red accent-1',
    warning: 'orange lighten-3',
  };


  export default {
    data() {
      return {
      }
    },
    methods: {
      getStatusColor(status) {
        return statusColors[status];
      },
      getBackgroundColor(status) {
        return backgroundColors[status];
      },
      calcItemBackgroundColor(item) {
        return item.errors && item.errors.length > 0 ? backgroundColors['error'] :
          (item.warnings && item.warnings.length > 0 ? backgroundColors['warning'] : "");
      },
    },
  };
</script>