import * as axiosConfig from '@client/axios/default.axiosConfig'
import persistentStore from '@store/persistentStore'


const cache = axiosConfig.setupCache();
const axiosInstance = axiosConfig.getNewInstance(global.MOCO_API_URL, cache.adapter, cache)


const configWithApiKey = () => {
  const config = {
    headers: {}
  };
  
  if (global.impersonationID) {
    config.headers['Authorization'] = `Token token=${global.MOCO_IMPERSONATION_KEY}`;
    config.headers['X-IMPERSONATE-USER-ID'] = global.impersonationID;
  } else {
    config.headers['Authorization'] = `Token token=${persistentStore.getAuthentication().mocoApiKey}`;
  }

  return config;
};

const configWithApiKeyAndUserID = (fromDate, toDate) => {
  const config = {
    headers: {},
    params: {}
  };
  
  if (global.impersonationID) {
    config.headers['Authorization'] = `Token token=${global.MOCO_IMPERSONATION_KEY}`;
    config.headers['X-IMPERSONATE-USER-ID'] = global.impersonationID;
    config.params.user_id = global.impersonationID ;
  } else {
    config.headers['Authorization'] = `Token token=${persistentStore.getAuthentication().mocoApiKey}`;
    config.params.user_id = persistentStore.getAuthentication().mocoUserID;
  }
  
  if (fromDate !== undefined) {
    config.params.from = fromDate;
  }
  if (toDate !== undefined) {
    config.params.to = toDate;
  }
  return config;
}

const createMocoSession = async function (email, password) {
  const data = {
    email: email,
    password: password
  };

  const session = (await axiosInstance.post('/session/', data)).data;
  return {
    apiKey: session.api_key,
    userID: session.user_id
  }
}

const getStaticUserDataFromMoco = async function() {
  const config = configWithApiKeyAndUserID();
  const configWithFrom = configWithApiKeyAndUserID('2020-01-01'); // TODO brauchen wir tatsächlich _alle_ daten von anfang an? oder reicht nicht die letzten paar monate?

  const employmentsPromise = axiosInstance.get('/users/employments', config);
  const holidaysPromise = axiosInstance.get('/users/holidays', config);
  const schedulesPromise = axiosInstance.get('/schedules', configWithFrom);

  const [employments, holidays, schedules] = await Promise.all([employmentsPromise, holidaysPromise, schedulesPromise]);

  return {
    userEmployments: employments.data,
    userHolidays: holidays.data,
    schedules: schedules.data
  }
}

const getActivities = async function(from, to) {
  const config = configWithApiKeyAndUserID(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));

  return (await axiosInstance.get('/activities', config)).data;
}

const getPresences = async function(from, to) {
  const config = configWithApiKeyAndUserID(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));

  return (await axiosInstance.get('/users/presences', config)).data;
}

const createActivity = async function(date, description, projectID, taskID, hours) {
  const data = {
    date: date.format('YYYY-MM-DD'),
    description: description,
    project_id: projectID,
    task_id: taskID,
    hours: hours
  }

  return (await axiosInstance.post('/activities', data, configWithApiKey())).data;
}

const createPresence = async function(date, timeFrom, timeTo, isHomeOffice) {
  const data = {
    date: date.format('YYYY-MM-DD'),
    from: timeFrom,
    to: timeTo,
    is_home_office: isHomeOffice ?? false
  }

  return (await axiosInstance.post('/users/presences', data, configWithApiKey())).data;
}

const updateActivity = async function(activityID, projectID, taskID, description, hours) {
  const data = {
    project_id: projectID,
    task_id: taskID,
    description,
    hours
  };

  return (await axiosInstance.put(`/activities/${activityID}`, data, configWithApiKey())).data;
}

const updatePresence = async function(presenceID, from, to, is_home_office) {
  const data = {
    from,
    to,
    is_home_office
  };

  return (await axiosInstance.put(`/users/presences/${presenceID}`, data, configWithApiKey())).data;
}

const changeIsHomeOfficeFlags = async function (presenceID, isHomeOffice) {
  await updatePresence(presenceID, undefined, undefined, isHomeOffice);
}

const deleteActivity = async function(activityID) {
  return (await axiosInstance.delete(`/activities/${activityID}`, configWithApiKey())).data;
}

const deletePresence = async function(presenceID) {
  return (await axiosInstance.delete(`/users/presences/${presenceID}`, configWithApiKey())).data;
}


const createHoliday = async function(date) {
  return await createSchedule(date, 4);
}

const createPublicHoliday = async function(date) {
  return await createSchedule(date, 2);
}

const createSchedule = async function(date, absenceCode) {
  const data = {
    date: date.format('YYYY-MM-DD'),
    absence_code: absenceCode
  }

  return (await axiosInstance.post('/schedules', data, configWithApiKey())).data;
}

const getSchedules = async function(from, to) {
  const config = configWithApiKeyAndUserID(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
  return (await axiosInstance.get('/schedules', config)).data;
}

const deleteSchedule = async function(scheduleID) {
  return (await axiosInstance.delete(`/schedules/${scheduleID}`, configWithApiKey())).data;
}

const clearCache = async function () {
  await axiosInstance.cache.clear();
}


export {
  createMocoSession,
  getStaticUserDataFromMoco,
  createActivity,
  createPresence,
  deletePresence,
  deleteActivity,
  getPresences,
  getActivities,
  updateActivity,
  updatePresence,
  changeIsHomeOfficeFlags,
  createHoliday,
  createPublicHoliday,
  getSchedules,
  deleteSchedule,
  clearCache
}