ADDITIONAL_APP_TITLE<template>
  <v-app>
    <v-app-bar app dense clipped-left flat class="pa-0 ma-0 justify-start" color="#2B2B2A">
      <v-img :src="require('./assets/RazzfazzLogo.png')" height="44px" width="140px" class="justify-start flex-grow-0"
        contain></v-img>
      <v-toolbar-title class="d-flex align-center justify-start pl-3 pr-1 white--text">
        SEQITracker
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div id='additionalTitle' class="font-weight-bold display-1 red--text">{{ additionalTitle }}</div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app :expand-on-hover="!this.otherSettings.navDrawerPinned"
      :mini-variant="!this.otherSettings.navDrawerPinned" permanent clipped width="330">
      <v-list id="authentication" dense>
        <v-list-item class="pl-2 pb-0">
          <v-list-item-avatar>
            <img :src="this.userData ? this.userData.avatar_url : require('./assets/avatar_placeholder.png')" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title data-cy="navbar-username">
              {{ this.userData ? this.userData.firstname + ' ' + this.userData.lastname : '' }}
            </v-list-item-title>
            <v-list-item-subtitle data-cy="navbar-teamname">
              {{ this.userData ? this.userData.unit.name : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-btn data-cy="pinnadel" icon @click="toggleNavDrawerPinned()">
            <v-icon v-if="!this.otherSettings.navDrawerPinned">mdi-pin</v-icon>
            <v-icon v-else>mdi-pin-outline</v-icon>
          </v-btn>
        </v-list-item>

        <v-list-item class="pl-3">
          <label v-if="this.authenticated" class="green--text caption">Online</label>
          <label v-if="!this.authenticated" class="grey--text caption">Offline</label>
        </v-list-item>

        <v-list-item link v-if="!this.authenticated">
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Anmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="this.displaySettingsArea" :to="{ path: global.ROUTES.einstellungen.path }" data-cy="navbar-einstellungen">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="this.displayTeamleadArea" :to="{ path: global.ROUTES.teamlead.path }" id="teamleadNavButton">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Teamlead Bereich</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="this.displayHRArea" :to="{ path: global.ROUTES.hr.path }" id="hrNavButton">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>HR Bereich</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="this.displayGLArea" :to="{ path: global.ROUTES.gl.path }" id="glNavButton">
          <v-list-item-icon>
            <v-icon>mdi-account-tie</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>GL Bereich</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="this.displayAdminArea" :to="{ path: global.ROUTES.admin.path }" id="adminNavButton">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Admin Bereich</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="this.displayQAArea" :to="{ path: global.ROUTES.qa.path }" data-cy="navbar-qa">
          <v-list-item-icon>
            <v-icon>mdi-test-tube</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>QA Bereich</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>

      <v-list id="navigation" dense nav>
        <v-list-item
          :to="{ name: global.ROUTES.monatsansicht.name, params: { year: this.$store.state.displayedMonth.year(), month: this.$store.state.displayedMonth.isoMonth() } }"
          data-cy="navbar-aktuellermonat">
          <v-list-item-icon>
            <v-icon>mdi-calendar-month</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <!--<v-list-item-title>Monatsansicht ({{this.$store.state.displayedMonth.format('MMMM YYYY')}})</v-list-item-title>-->
            <v-list-item-title>Monatsansicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <section v-if="this.startedMonatsabschluesse.length > 0">
        <v-divider></v-divider>

        <v-list id="monthclosing" dense nav>
          <v-list-item>
            <v-list-item-subtitle>Offene Monatsabschlüsse</v-list-item-subtitle>
          </v-list-item>
          <v-list-item :id="startedMonatsabschluss.jiraKey" v-for="startedMonatsabschluss in this.startedMonatsabschluesse"
            :key="startedMonatsabschluss.jiraKey"
            :to="{ path: `${global.ROUTES.monatsabschluss.path}/${startedMonatsabschluss.reporter.emailAddress.replace('@seqis.com', '')}/${startedMonatsabschluss.year}/${startedMonatsabschluss.month}` }"
            exact>
            <v-list-item-icon>
              <v-icon>mdi-calendar-month</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ startedMonatsabschluss.reporter.displayName }}
                ({{ beautifyDateMonatsabschluss(startedMonatsabschluss) }})</v-list-item-title>
              <v-list-item-subtitle>{{ startedMonatsabschluss.status }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </section>

      <section v-if="this.leadingProjects.length > 0">
        <v-divider></v-divider>

        <v-list id="projects" dense nav>
          <v-list-item>
            <v-list-item-subtitle>
              Projektübersicht ({{ this.$store.state.displayedMonth.format('MMMM YYYY') }})
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-for="leadingProject in this.leadingProjects" :key="leadingProject.id"
            :to="{ name: global.ROUTES.projekt.name, params: { year: $store.state.displayedMonth.year(), month: $store.state.displayedMonth.isoMonth() }, query: { projectID: leadingProject.id } }"
            exact data-cy="navbar-project">
            <v-list-item-icon>
              <v-icon :color="leadingProject.overdue ? 'red' : ''">mdi-calendar-month</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ leadingProject.name }} ({{ leadingProject.identifier }})</v-list-item-title>
              <v-list-item-subtitle>Zieltermin: {{ beautifyDateProjektabschluss(leadingProject) }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="leadingProject.status">Status: {{ leadingProject.status
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </section>

      <v-divider></v-divider>

      <v-list id="authentication" dense>
        <v-list-item link @click="removeAuthentication()" v-if="this.authenticated" data-cy="navbar-abmelden">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <Authentication v-if="!this.authenticated" @authenticated="authenticationSucess" />
        <v-alert id="fatalError" v-if="this.error && this.isFatalError" type="error" elevation="6" transition="scroll-y-reverse-transition" dismissible>
          {{this.error.msg}}
        </v-alert>
        <v-snackbar id="error" v-if="this.error && this.isFatalError == false" v-model="this.error" color="red accent-2" timeout="5000"
          app centered elevation="6"
        >
          {{this.error.msg}}
        </v-snackbar>
        <router-view v-if="!this.isFatalError && this.authenticated && !this.loading && this.$route.meta.key" :key="this.$route.meta.key(this.$route)">
        </router-view>
        <v-banner v-else-if="!this.isFatalError && !this.loading">
          <v-avatar slot="icon" color="error" size="40">
            <v-icon icon="mdi-lock" color="white">
              mdi-lock
            </v-icon>
          </v-avatar>
          Deine Daten können ohne Anmeldung nicht von Moco geladen werden. Bitte melde Dich mit Deinen Moco Zugangsdaten
          an...
        </v-banner>
      </v-container>
      <UpdateAvailableBar></UpdateAvailableBar>
      <br />
      <footer v-if="!this.loading" id="footer">
        <div id="buildinfo">
          {{ serverBuildInfo }} / {{ clientBuildInfo }}
        </div>
      </footer>
    </v-main>
  </v-app>
</template>

<script>
import dayjs from '@util/dayjs';
import buildInfo from '@/../buildinfo.json'
import * as sqtClient from './client/client.sqt';
import * as mocoClient from './client/client.moco';
import * as monatsabschlussClient from '@client/client.monatsabschluss';
import * as projektabschlussClient from '@client/client.projektabschluss';

import Authentication from '@components/Authentication.vue';
import UpdateAvailableBar from '@components/UpdateAvailableBar.vue'

export default {
  name: 'App',
  components: {
    Authentication,
    UpdateAvailableBar
  },
  created() {
    this.otherSettings = this.$store.getters.otherSettings;
    global.impersonationID = this.$route.query?.impersonation;

    this.getVersionInfo();
    this.initialLoadUserData();

    this.$root.$on('reloadApp', async () => {
      this.$root.$emit('startLoading');

      await Promise.all([sqtClient.clearCache(), monatsabschlussClient.clearCache(), projektabschlussClient.clearCache(), mocoClient.clearCache()]);
      
      await this.$store.dispatch('reloadStaticUserData', global.impersonationID);

      this.loadLeadingProjects();
      this.loadStartedMonatsabschluesse();

      this.$root.$emit('reload');
    });
  },

  beforeDestroy() {
    this.$root.$off('reloadApp', this.startLoading);
  },

  data: () => ({
    serverBuildInfo: undefined,
    clientBuildInfo: `Client: V${buildInfo.version}/${buildInfo.ci} @ ${buildInfo.date}`,
    loading: false,
    otherSettings: {},
    startedMonatsabschluesse: [],
    leadingProjects: []
  }),

  computed: {
    error: function () {
      return this.$store.getters.error;
    },
    isFatalError: function () {
      return this.error?.fatal;
    },
    displayedDate: function () {
      return this.$store.state.displayedMonth;
    },

    displayHRArea: function () {
      return this.$store.getters.staticUserData?.user?.roles.isHR && global.impersonationID == undefined;
    },
    displayAdminArea: function () {
      return this.$store.getters.staticUserData?.user?.roles.isRazzfazz && global.impersonationID == undefined;
    },
    displayTeamleadArea: function () {
      return this.$store.getters.staticUserData?.user?.roles.isTL && global.impersonationID == undefined;
    },
    displayGLArea: function () {
      return this.$store.getters.staticUserData?.user?.roles.isGL && global.impersonationID == undefined;
    },
    displayQAArea: function () {
      return process.env.VUE_APP_LOCAL_STORAGE_ENV == 'staging' && global.impersonationID == undefined;
    },
    displaySettingsArea: function() {
      return global.impersonationID == undefined;
    },

    userData: function () {
      return this.$store.getters.staticUserData?.user;
    },

    additionalTitle: function () {
      if (this.userData && global.impersonationID && global.impersonationID == this.$store.state.impersonationID) {
        return global.ADDITIONAL_APP_TITLE + ' Impersonated: ' + this.userData.firstname + ' ' + this.userData.lastname;
      } else {
        return global.ADDITIONAL_APP_TITLE;
      }
    },

    authenticated: function () {
      return this.$store.getters.userAuthenticationData.authenticated;
    }
  },

  methods: {
    beautifyDateMonatsabschluss: function (monatsabschluss) {
      return dayjs.iso({
        year: monatsabschluss.year,
        month: monatsabschluss.month
      }).format('MMMM YYYY');
    },

    beautifyDateProjektabschluss: function (project) {
      return project.finish_date ? dayjs(project.finish_date).format('DD.MM.YYYY') : 'n/a';
    },

    async getVersionInfo() {
      try {
        const serverBuildInfo = await sqtClient.getVersion();
        this.serverBuildInfo = `Server: V${serverBuildInfo.version}/${serverBuildInfo.ci} @ ${serverBuildInfo.date}`;
      } catch (err) {
        console.error(err);
        this.$store.dispatch('setFatalError", "Es konnte keine Verbindung zum SEQITracker Server aufgebaut werden.');
        this.loading = false;
        return;
      }
    },

    async initialLoadUserData() {
      this.loading = true;

      if (this.authenticated) {
        if ((this.$store.getters.staticUserData?.user === undefined) || 
          (global.impersonationID && global.impersonationID != this.$store.state.impersonationID)) {
          
          await this.$store.dispatch('loadStaticUserData', global.impersonationID);
          if (this.isFatalError) {
            return;
          }
        }

        this.loadLeadingProjects();
        this.loadStartedMonatsabschluesse();
      }

      this.$store.dispatch('initEnabledRules'); // TODO das kann wahrscheinlich auch raus
      this.loading = false;
    },

    async loadLeadingProjects() {
      if (this.$store.getters.staticUserData.leadingProjects == undefined) {
        this.leadingProjects = [];
        return;
      }

      const leadingProjects = this.$store.getters.staticUserData.leadingProjects.sort((p1, p2) => p1.name.toLowerCase() > p2.name.toLowerCase() ? 1 : -1);
      const displayedLeadingProjects = leadingProjects.filter((project) => dayjs(project.created_at).isSameOrBefore(this.displayedDate, 'month'));
      
      await Promise.all(displayedLeadingProjects.map(async (project) => {
        const jiraTicket = await projektabschlussClient.getProjektabschluss(undefined, this.displayedDate.isoMonth(), this.displayedDate.year(), project.identifier);
        if (jiraTicket) {
          project.status = jiraTicket.status;
        } else {
          const projectActivities = await sqtClient.getMocoProjectLeadActivities(project.id, this.displayedDate.startOf('month'), this.displayedDate.endOf('month'));
          if (projectActivities?.activities?.length == 0) {
            project.status = global.projectClosing.status.noActivities;
          } else {
            project.status = global.projectClosing.status.open;
          }
        }
        project.overdue = project.finish_date != undefined && dayjs(project.finish_date).isBefore(dayjs());
      }));

      this.leadingProjects = displayedLeadingProjects;
    },

    async loadStartedMonatsabschluesse() {
      if (!this.authenticated) {
        this.startedMonatsabschluesse = [];
        return;
      }

      const startedMonatsabschluesse = await monatsabschlussClient.getStartedMonatsabschluesse();
      this.startedMonatsabschluesse = startedMonatsabschluesse.sort((m1, m2) => {
        const surname1 = m1.reporter.emailAddress.split('@')[0].split('.')[1];
        const surname2 = m2.reporter.emailAddress.split('@')[0].split('.')[1];
        return surname1.toLowerCase() > surname2.toLowerCase() ? 1 : -1;
      });
    },

    async authenticationSucess(result) {
      this.$store.dispatch('clearError');
      this.loading = true;
      await this.$store.dispatch('setUserAuthenticationData', result);
      this.initialLoadUserData();
    },

    async removeAuthentication() {
      if (global.impersonationID) {
        window.close(``, `_parent`, ``);
      } else {
        await this.$store.dispatch('clearUserAuthenticationData', true);
        await this.$store.dispatch('clearStaticUserData');
  
        await Promise.all([sqtClient.clearCache(), mocoClient.clearCache()]);
  
        this.leadingProjects = [];
        this.startedMonatsabschluesse = [];
  
        if (this.$route.path != `${global.ROUTES.monatsansicht.path}/${dayjs().year()}/${dayjs().isoMonth()}`) {
          this.$router.push({ path: `${global.ROUTES.monatsansicht.path}/${dayjs().year()}/${dayjs().isoMonth()}` });
        }
      }
    },

    toggleNavDrawerPinned() {
      this.otherSettings.navDrawerPinned = !this.otherSettings.navDrawerPinned;
      this.$store.dispatch('setOtherSettings', this.otherSettings)
    }
  },

  watch: {
    displayedDate: function () {
      this.loadLeadingProjects();
      this.loadStartedMonatsabschluesse();
    }
  }
};
</script>

<style lang="scss">
#monthclosing #item,
#projects #item {
  height: 50px;
}

#monthclosing .v-list-item__icon,
#projects .v-list-item__icon {
  margin-top: 16px;
  margin-bottom: 16px;
}

#error {
  white-space: pre;
  /* allow line breaks */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

#buildinfo {
  font-size: 12px;
}

// notwendig nach dependency updates
.row {
  margin: 0px -12px 0px -12px;
}

textarea {
  line-height: 1.5 !important
} 
</style>