<template>
  <transition name="modal-fade">
    <v-dialog v-model="shown" persistent max-width="500">
      <v-card>
        <v-card-title class="pl-2 headline">
          SEQIS Moco Anmeldung
        </v-card-title>

        <section class="modal-authentication-body">
          <slot name="body">
            <v-text-field data-cy="loginEmailInput" v-model="mocoEmail" @keydown.enter="login" label="Moco Benutzer (Email)" placeholder="vorname.nachname@seqis.com" dense :autofocus="!mocoEmail">
            </v-text-field>
            <v-text-field data-cy="loginPasswordlInput" v-model="mocoPassword" @keydown.enter="login" label="Moco Passwort" type="password" dense :autofocus="!!mocoEmail"></v-text-field>
            <v-row>
              <v-col cols="12">
                <label class="red--text">{{ errorMessage }}</label>
              </v-col>
            </v-row>
          </slot>
        </section>

        <v-card-actions class="justify-end">
          <v-btn type="button" @click="login()" :loading="this.loading" :disabled="!mocoEmail || !mocoPassword" color="#c00000" class="white--text">
            Anmelden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
  import * as mocoClient from '@client/client.moco.js'

  export default {
    name: 'AuthenticationDialog',
    data() {
      return {
        shown: true,
        mocoEmail: this.$store.getters.userAuthenticationData.email,
        mocoPassword: undefined,
        mocoAPIKey: this.$store.getters.userAuthenticationData.mocoApiKey,
        mocoUserID: this.$store.getters.userAuthenticationData.mocoUserID,
        errorMessage: undefined,
        loading: false
      }
    },
    created() {},
    methods: {
      async login() {
        this.errorMessage = undefined;

        if (this.mocoEmail == '' || this.mocoPassword == '') {
          return;
        }
        try {
          const mocoSession = await mocoClient.createMocoSession(this.mocoEmail, this.mocoPassword);
          this.loading = true;
          this.$emit('authenticated', {
            email: this.mocoEmail,
            mocoUserID: mocoSession.userID,
            mocoApiKey: mocoSession.apiKey
          });
        } catch(e) {
          this.errorMessage = 'Email oder Passwort falsch :-(';
        }
      }
    },
  };
</script>

<style>
  .modal-authentication-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
  }

  .modal-authentication {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 350px;
  }

  .modal-authentication-header,
  .modal-authentication-footer {
    padding: 15px;
    display: flex;
  }

  .modal-authentication-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-authentication-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-authentication-body {
    overflow: hidden; /* Hide scrollbar */
    position: relative;
    padding: 20px 10px;
  }
</style>