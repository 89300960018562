import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'
import FormData from 'form-data';

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/monatsabschluss`);


const createMonatsabschluss = async function (dateOfMonth, comment, monthData) {
  const data = {
    gzkStand: monthData?.hours.flexTimeBalance,
    dateOfMonth: dateOfMonth.format('YYYY-MM-DD'),
    comment: comment,
    persistMonth: monthData ? Buffer.from(JSON.stringify(monthData)).toString('base64') : undefined
  };

  return (await axiosInstance.post('/start', data)).data;
}

const getMonatsabschluss = async function (jiraKey, reporterEmail, month, year) {
  // TODO boolean und query param für fetchMonthData
  const config = {
    params: {
      reporterEmail: reporterEmail,
      month: month,
      year: year
    }
  };

  return (await axiosInstance.get(`${jiraKey ? '/' + jiraKey : ''}`, config)).data;
}

const getStartedMonatsabschluesse = async function () {
  return (await axiosInstance.get('/allStarted')).data;
}

const transition = async function (jiraKey, transition, comment, monthData) {
  const data = {
    transition: transition,
    comment: comment,
    persistMonth: monthData ? Buffer.from(JSON.stringify(monthData)).toString('base64') : undefined,
    gzkStand: monthData ? monthData.hours.flexTimeBalance : undefined,
  };
  
  await axiosInstance.put(`/${jiraKey}/transition`, data);
};


const addAttachments = async function (dateOfMonth, attachments) {
  const formData = new FormData();
  formData.append('dateOfMonth', dateOfMonth.format('YYYY-MM-DD'));

  attachments.forEach((attachment) => {
    formData.append('attachments', attachment);
  });

  try {
    return (await axiosInstance.put('/attachments', formData, {
      headers: {
        'content-Type': 'multipart/form-data'
      }
    })).data;
  } catch {
    return undefined;
  }
}

const deleteAttachment = async function (dateOfMonth, attachmentID) {
  const config = {
    params: {
      dateOfMonth: dateOfMonth.format('YYYY-MM-DD'),
      attachmentID: attachmentID
    }
  };
  
  try {
    return (await axiosInstance.delete('/attachment', config)).data; // liefert "" zurück
  } catch {
    return undefined;
  }
}

const getAttachment = async function (url) {
  const config = {
    params: {
      url: url
    },
    responseType: 'arraybuffer'
  };
  
  try {
    return (await axiosInstance.get('/attachment', config)).data;
  } catch {
    return undefined;
  }
}

const clearCache = async function () {
  await axiosInstance.cache.clear();
}

const clearCacheSpecificRoute = async function (route) {
  await axiosInstance.cache.removeItem(`${axiosInstance.defaults.baseURL}${route}`);
}


export {
  createMonatsabschluss,
  getMonatsabschluss,
  getStartedMonatsabschluesse,
  transition,
  addAttachments,
  deleteAttachment,
  getAttachment,
  clearCache,
  clearCacheSpecificRoute
}