<template lang='html'>
  <section class='day'>

    <v-expansion-panel-header class="pt-0 pb-0" icon="mdi-lock" :color="getBackgroundColor(dayType)">
      <section class='daySummary'>
        <v-row no-gutters class="body-2" style="d-flex flex-row">
          <v-col cols="auto" class="">
            <v-row>
              <div @mouseover="dayIconMouseOver" @mouseleave="dayIconMouseLeave" class="day-icon-wrapper">
                <v-btn v-if="(hoveringOverDayIcon || loadingHomeOfficeStatus || homeOfficeChanged) && entryUpdateEnabled && dayData.presences.length > 0 && this.dayIcon === ''" 
                  icon @click.native.stop="changeHomeOfficeStatus()" :loading="loadingHomeOfficeStatus" class="button-day-icon">
                  <v-icon :class="'ghost-icon ' + homeOfficeChangedIconClass()">mdi-home</v-icon>
                </v-btn>
                <v-btn v-if="entryUpdateEnabled && this.dayIcon === 'mdi-home'" icon @click.native.stop="changeHomeOfficeStatus()" 
                  :loading="loadingHomeOfficeStatus" class="button-day-icon"> <!-- kein v-else-if weil sonst beim toggeln das icon anscheinend nochmal kurz aufblinkt -->
                  <v-icon :class="'home-office-icon ' + homeOfficeChangedIconClass()">{{this.dayIcon}}</v-icon>
                </v-btn>
                <v-icon v-else class="non-button-day-icon">{{this.dayIcon}}</v-icon>
              </div>
              <v-card class="pa-2 font-weight-bold d-flex flex-column" min-width="115px" flat color='transparent'>
                <label class="grey--text overline" id='weekDayName'>{{weekDayName}}</label>
                <label id='date'>{{formatedDate}}</label>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="auto" class="d-flex flex-grow-1">
            <UserHoursSummary :displayData="dayData" :visibility="visibility" :extrapolateFlexTime="extrapolateFlexTime"></UserHoursSummary>
          </v-col>
        </v-row>
      </section>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="pa-0" flat color="white">
      <section class='dayEntry'>
        <v-container class="grey lighten-5 pa-0" style="max-width: 100%">
          <v-col class="pa-0">
            <v-row class="pb-2" id="errorMessageBanner" v-if="dayData.errors && dayData.errors.length > 0">
              <v-sheet v-for="error in dayData.errors" :key="error.code"
                class="pa-2 mt-1 mb-1 text-center white--text" min-width="100%" elevation="5"
                :color="getStatusColor('error')">
                {{error.message}}
              </v-sheet>
            </v-row>
            <v-row class="pb-2" id="warningMessageBanner" v-if="dayData.warnings && dayData.warnings.length > 0">
              <v-sheet v-for="warning in dayData.warnings" :key="warning.code"
                class="pa-2 mt-1 mb-1  text-center white--text" min-width="100%" elevation="5"
                :color="getStatusColor('warning')">
                {{warning.message}}
              </v-sheet>
            </v-row>
          </v-col>
          <v-row no-gutters style="flex-wrap: wrap;">
            <v-col cols="3" class="flex-grow-1 flex-shrink-0">
              <v-card id="presences" class="pa-2" height="100%" flat>
                <label id="presenceTableHeader" class="body-1">Haben </label>
                <label id="presenceTotal" class="body-1">{{dayData.hours.presences}} h</label>
                <label id="presenceTableHeader" class="body-1"> / Pausen </label>
                <label id="presenceTotal" class="body-1">{{dayData.hours.totalBreak}} h</label>
                <v-data-table :headers="presenceHeaders" :items="dayData.presences" item-key="id" 
                  class="elevation-1" :item-class="(presence) => {
                    const color = calcItemBackgroundColor(presence)
                    return presence.breakBefore > 0 ? 'break-before '+ color : color}" id="presenceTable" hide-default-footer :items-per-page="100"
                  @click:row="editPresence">
                </v-data-table>
              </v-card>
            </v-col>

            <v-col cols="9" class="flex-grow-1 flex-shrink-0">
              <v-card id="activities" class="pa-2" height="100%" flat>
                <label id="activityTableHeader" class="body-1">Leistungszeiten </label>
                <label id="activityTotal" class="body-1">{{dayData.hours.activities}} h</label>
                <v-data-table :headers="activityHeaders" :items="dayData.activities" item-key="id" :item-class="calcItemBackgroundColor"
                  class="elevation-1" id="activityTable" hide-default-footer :items-per-page="100" 
                  @click:row="editActivity">
                  <template v-slot:item.billable="{ item }">
                    <v-icon v-if="item.billable">mdi-check</v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expansion-panel-content>

    <CreateEditPresenceActivity ref="editPresenceActivity" :dayData="this.dayData" :alterOriginalEntries="false"></CreateEditPresenceActivity>

  </section>
</template>

<script lang='js'>
  import dayjs from '@util/dayjs';

  import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue';
  import UserHoursSummary from '@components/user/UserHoursSummary.vue';
  import CreateEditPresenceActivity from '@components/CreateEditPresenceActivity.vue';
  import * as mocoClient from '@client/client.moco';

  export default {
    name: 'daySummary',
    props: ['dayData', 'extrapolateFlexTime', 'entryUpdateEnabled'],
    mixins: [StatusColorCalculator],
    components: {
      UserHoursSummary,
      CreateEditPresenceActivity
    },
    created() {},
    data() {
      return {
        visibility: {
          hideHoursWorked: false,
        },
        hoveringOverDayIcon: false,
        homeOfficeChanged: false,
        loadingHomeOfficeStatus: false,
        editPresenceActivityVisible: false,
        presenceHeaders: [{
            text: 'Von',
            value: 'from'
          },
          {
            text: 'Bis',
            value: 'to'
          },
          {
            text: 'h',
            value: 'duration'
          }
        ],
        activityHeaders: [{
            text: 'h',
            value: 'hours',
            width: "8%"
          },
          {
            text: 'Projekt',
            value: 'project.name',
            width: "20%"
          },
          {
            text: 'Aufgabe',
            value: 'task.name',
            width: "20%"
          },
          {
            text: 'Verr.',
            value: 'billable',
            width: "8%",
            sortable: false
          },
          {
            text: 'Beschreibung',
            value: 'description',
            width: "44%"
          }
        ]
      }
    },

    computed: {
      weekDayName: function () {
        if (this.dayData.isPublicHoliday) {
          return 'Feiertag';
        } else {
          return dayjs(this.dayData.date, 'YYYY-MM-DD').format('dddd');
        }
      },
      

      formatedDate: function () {
        if (dayjs(this.dayData.date).isSame(dayjs(), 'day')) {
          return 'Heute';
        } else {
          return dayjs(this.dayData.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
        }
      },

      dayIcon: function() {
        if (this.dayData.isHomeOffice) {
          return "mdi-home";
        } else if (this.dayData.isUserHoliday) {
          return "mdi-airplane"
        } else if (this.dayData.isPublicHoliday) {
          return "mdi-star"
        } else {
          return "";
        }
      },

      dayType: function () {
        if (this.dayData.isPublicHoliday) {
          return 'publicHoliday';
        } else if (this.dayData.isUserHoliday) {
          return 'userHoliday';
        } else if (this.dayData.isWeekend) {
          return 'weekend';
        } else {
          return 'default';
        }
      }
    },

    methods: {
      editPresence(presence) {
        if (this.entryUpdateEnabled == false) {
          return;
        }

        this.$refs.editPresenceActivity.editPresence(presence);
      },

      editActivity (activity) {
        if (this.entryUpdateEnabled == false) {
          return;
        }

        this.$refs.editPresenceActivity.editActivity(activity);
      },

      async changeHomeOfficeStatus() {
        this.loadingHomeOfficeStatus = true;
        this.homeOfficeChanged = true;
        
        await mocoClient.changeIsHomeOfficeFlags(this.dayData.presences[0].id, !this.dayData.isHomeOffice);
        
        this.loadingHomeOfficeStatus = false;

        //TODO https://michaelnthiessen.com/avoid-mutating-prop-directly/
        this.dayData.isHomeOffice = !this.dayData.isHomeOffice;  // eslint-disable-line
      },

      homeOfficeChangedIconClass() {
        if (this.homeOfficeChanged === true) {
          if (this.dayData.isHomeOffice) {
            return 'home-office-added';
          } else {
            return 'home-office-removed';
          }
        } else {
          return '';
        }
      },

      dayIconMouseLeave() {
        this.hoveringOverDayIcon = false;
        this.homeOfficeChanged = false;
      },
      
      dayIconMouseOver() {
        this.hoveringOverDayIcon = true;
      }
    }
  }
</script>
<style lang="scss">
#activityTable tbody tr,
#presenceTable tbody tr {
  cursor: pointer;
}
.day-icon-wrapper {
  width: 32px;
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
}
.button-day-icon {
  width: 32px !important;
  height: 32px !important;
}
.non-button-day-icon {
  padding: 4px;
}
.ghost-icon {
  opacity: 0;
  transition: 0.3s !important;
}
.ghost-icon:hover {
  opacity: 0.5;
}
tr.break-before td {
  border-top: 2px solid grey;
}
.home-office-icon:hover {
  opacity: 0.5;
}
.home-office-added,
.home-office-added:hover {
  opacity: 1;
}
.home-office.removed,
.home-office-removed:hover {
  opacity: 0;
}

</style>