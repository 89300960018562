<template lang="html">
  <v-card class="ma-5" flat>
    <v-tabs id="GLTabs" color="black" icons-and-text>
      <v-tabs-slider color="#c00000"></v-tabs-slider>

      <v-tab class="pa-8" id="employees" key="employees"
        :to="{ path: global.ROUTES.gl.path + global.ROUTES.gl.employees.path }">
        Mitarbeiter anzeigen
        <v-icon>mdi-account-group</v-icon>
      </v-tab>

      <v-tabs-items>
        <v-divider></v-divider>
        <router-view></router-view>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script lang="js">

export default {
  name: 'GL',
}

</script>