import persistentStore from '@store/persistentStore';
import * as axiosConfig from '@client/axios/default.axiosConfig';

const getNewInstance = function(baseURL, customCacheOptions = {}) {
  const cache = axiosConfig.setupCache(customCacheOptions);
  const axiosInstance = axiosConfig.getNewInstance(baseURL, cache.adapter, cache);
  
  axiosInstance.interceptors.request.use(function (config) {
    const token = persistentStore.getAuthentication().jwt;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    const mocoApiKey = persistentStore.getAuthentication().mocoApiKey;
    const mocoUserID = persistentStore.getAuthentication().mocoUserID;
    
    if (mocoApiKey && mocoUserID) {
      config.headers['X-Moco-API-Key'] = mocoApiKey;
      config.headers['X-Moco-UserID'] = mocoUserID;
    }

    if (global.impersonationID) {
      if (config.params) {
        config.params.impersonation = global.impersonationID;
      } else {
        config.params = { impersonation: global.impersonationID }
      }
    }
    
    return config;
  });
  
  axiosInstance.interceptors.response.use(function (response) {
    const token = response.headers.authorization;
    if (token && token != persistentStore.getAuthentication().jwt) {
      persistentStore.addSQTJWT(token);
    }
    return response;
  });

  return axiosInstance;
}

export {
  getNewInstance
} 
