<template lang="html">
  <section class="monthTable">
    <!-- Headerdaten Monat -->
    <v-row no-gutters class="pt-2 pb-4 pl-12 pr-12 " justify="center" align="center">
      <v-col cols="auto" class="">
        <v-card class="pa- d-flex flex-column text-center" flat color='transparent'>
          <label id="flexTimeLastMonthHeaderPageTop" class="grey--text overline">{{ isFlextimeForecast ? "Übertrag Gleitzeitkonto (Vor.)" : "Übertrag Gleitzeitkonto"}}</label>
          <label v-if="monthData.hours" 
            id="flexTimeLastMonthPageTop">{{monthData.hours.flexTimeLastMonth != undefined ? monthData.hours.flexTimeLastMonth + ' h': 'ausständig'}}</label>
        </v-card>
      </v-col>
      <v-col cols="auto" class="">
        <v-card class="px-12 d-flex flex-column text-center" flat color='transparent'>
          <label id="remainingHolidaysHeaderPageTop" class="grey--text overline">Resturlaub Monatsanfang</label>
          <label v-if="monthData.holidays" id="remainingHolidaysPageTop">{{monthData.holidays.atStartOfMonth}}
            Tag(e)</label>
        </v-card>
      </v-col>
      <v-col cols="auto" class="">
        <v-card class="pa-2 d-flex flex-column text-center" flat color='transparent'>
          <label id="plannedHolidaysHeader" class="grey--text overline">Geplanter Urlaub (Monat/Gesamt)</label>
          <label v-if="monthData.holidays" id="plannedHolidays">{{monthData.holidays.plannedInMonth + '/' + monthData.holidays.plannedInFuture}}
            Tag(e)</label>
        </v-card>
      </v-col>
    </v-row>
    <!-- /Headerdaten Monat -->

    <!-- Monatsansicht -->
    <v-row>
      <v-col cols="0" md="1"></v-col>
      <v-col cols="auto" md="10">
        <v-expansion-panels :value="expandedWeeks" multiple hover accordion class="pa-0" id="currentMonthTable"> 
          <v-expansion-panel v-for="week in monthData.weeks" :key="week.calendarWeek + monthData.fullName"
            :class="'pa-0 pl-3 '+ getStatusColor(week.status)">
            <UserWeek :weekData="week" :extrapolateFlexTime="extrapolateFlexTime" :entryUpdateEnabled="entryUpdateEnabled" :monthTableRef="selfReference"></UserWeek> <!-- test: kann man hier nicht einfach "this" schreiben? -->
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="0" md="1"></v-col>
    </v-row>
    <!-- /Monatsansicht -->

    <!-- Monatswarnungen/Monatserrors -->
    <v-row v-if="monthData.errors && monthData.errors.length > 0 || monthData.warnings && monthData.warnings.length > 0">
      <v-col cols="0" md="1" class="pt-0 pb-0"></v-col>
      <v-col md="10" class="pt-0 pb-0">
        <v-row class="pl-3 pr-3" id="monthErrorMessageBanner" v-if="monthData.errors && monthData.errors.length > 0">
          <v-sheet v-for="error in monthData.errors" :key="error.code" 
            class="pa-2 mt-1 mb-1 text-center white--text" min-width="100%" elevation="5"
            :color="getStatusColor('error')">
            {{error.message}}
          </v-sheet>
        </v-row>
        <v-row class="pl-3 pr-3" id="monthWarningMessageBanner" v-if="monthData.warnings && monthData.warnings.length > 0">
          <v-sheet v-for="warning in monthData.warnings" :key="warning.code"
            class="pa-2 mt-1 mb-1 text-center white--text" min-width="100%" elevation="5"
            :color="getStatusColor('warning')">
            {{warning.message}}
          </v-sheet>
        </v-row>
      </v-col>
      <v-col cols="0" md="1" class="pt-0 pb-0"></v-col>
    </v-row>

    <!-- Wochen/Tage aus- und einklappen -->
    <v-row no-gutters>
      <v-col cols="1" class="pa-0"></v-col>
      <v-col cols="auto" class="pt-5">
        <v-btn id="expandWeeksButton" @click.native="$event => expandAllWeeks()" color="#c00000" class="mr-3 white--text">
          Wochen ausklappen
        </v-btn>
        <v-btn id="collapseWeeksButton" @click.native="$event => collapseAllWeeks()" color="#c00000" class="mr-3 white--text">
          Wochen einklappen
        </v-btn>
      </v-col>
      <v-col cols="auto" class="pl-10 pt-5">
        <v-btn id="expandDaysButton" @click.native="$event => expandAllDays()" color="#c00000" class="mr-3 white--text">
          Tage ausklappen
        </v-btn>
        <v-btn id="collapseDaysButton" @click.native="$event => collapseAllDays()" color="#c00000" class="mr-3 white--text">
          Tage einklappen
        </v-btn>
      </v-col>
    </v-row>
    <!-- /Wochen/Tage aus- und einklappen -->

    <!-- Projekte & Aufgaben / Anhänge -->
    <v-row>
      <v-col cols="0" md="1"></v-col>
      <v-col cols="auto" md="10">
        <v-expansion-panels>
          <v-expansion-panel id="projectActivitiesPanel">
            <v-expansion-panel-header>
              <v-row class="align-center justify-center title grey--text" no-gutters>
                Zusammenfassung Projekte & Aufgaben
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="this.monthActivities && this.monthActivities.length > 0" eager>
              <ProjectActivities :activities="this.monthActivities" :presences="this.monthPresences" :entryUpdateEnabled="entryUpdateEnabled" type="usermonth" :key="this.monthData.fullName"></ProjectActivities>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else>
              <v-row class="d-flex justify-center">
                Keine Daten vorhanden
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          
          <v-expansion-panel id="attachmentsPanel" >
            <v-expansion-panel-header>
              <v-row class="align-center justify-center title grey--text" no-gutters>
                Anhänge ({{ this.attachments ? this.attachments.length : '0' }})
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-0 pa-0">
              <FileManager class="ma-0 pa-0" :attachments="this.attachments" :fileManipulationEnabled="this.fileManipulationEnabled" 
                :fileUploadLoading="this.fileUploadLoading" :fileDownloadLoadingIDs="this.fileDownloadLoadingIDs" :fileDeleteLoadingIDs="this.fileDeleteLoadingIDs"
                @addAttachments="this.addAttachments" @downloadAttachment="this.downloadAttachment" @deleteAttachment="this.deleteAttachment">
              </FileManager>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="0" md="1"></v-col>
    </v-row>
    <!-- /Projekte & Aufgaben / Anhänge -->

    <!-- Zusammenfassung -->
    <v-row no-gutters style="flex-wrap: wrap;" class="pt-6 ">
      <v-col cols="0" md="1"></v-col>
      <v-col>
        <v-card v-if="monthData.hours" id="expectedActualBlock" style="flex-basis: 100px;"
          class="d-flex flex-column pt-2 pb-2 pr-2 mt-2 mb-2 mr-2 flex-grow-1">
          <label id="expectedActualBlockHeader" class="title pa-2 mb-2 text-center grey--text">Stundensaldo </label>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="presenceTotalHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Haben
            </label>
            <label id="presenceTotal" class="black--text subtitle-1 justify-end">
              {{monthData.hours.presences}} h
            </label>
          </div>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="expectedTotalHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Soll
            </label>
            <label id="expectedTotal" class="black--text subtitle-1 justify-end">
              -{{monthData.hours.expected}} h
            </label>
          </div>
          <div class="d-flex flex-row pl-2 pr-2">
            <label id="overtimeFlatHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Ü-Pauschale
            </label>
            <label id="overtimeFlat" class="black--text subtitle-1 justify-end">
              {{makeNumberSigned(monthData.hours.overTimeFlat, '', true)}} h
            </label>
          </div>

          <v-divider class="pl-2 pr-2"></v-divider>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="totalDiffHeader" 
              class="d-flex flex-grow-1 black--text subtitle-1 justify-start font-weight-bold">
              Stundensaldo {{monthData.name}} {{ extrapolateFlexTime ? '(Heute/Vor.)' : '' }}
            </label>
            <div class="black--text subtitle-1 justify-end font-weight-bold">
              <label id="totalDiff">
                {{makeNumberSigned(monthData.hours.totalDiff)}}<span v-if="extrapolateFlexTime">/{{makeNumberSigned(monthData.hours.totalDiffExtrapolated)}}</span> h
              </label>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col>
        <v-card v-if="monthData.hours" id="flexTimeBlock" style="flex-basis: 100px;" class="d-flex flex-column pa-2 ma-2 flex-grow-1">
          <label id="flexTimeBlockHeader" class="title pa-2 mb-2 text-center grey--text">Gleitzeitkonto</label>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="flexTimeLastMonthHeaderPageBottom"
              class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              {{ isFlextimeForecast ? "Stand Vormonat (Vor.)" : "Stand Vormonat" }}
            </label>
            <label id="flexTimeLastMonthPageBottom" class="black--text subtitle-1 justify-end">
              {{monthData.hours.flexTimeLastMonth != undefined ? makeNumberSigned(monthData.hours.flexTimeLastMonth) + ' h' : 'ausständig'}}
            </label>
          </div>
          <div class="d-flex flex-row pl-2 pr-2">
            <label id="flexTimeCorrectionHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Manuelle Korrektur
            </label>
            <label id="flexTimeCorrection" class="black--text subtitle-1 justify-end">
              {{makeNumberSigned(monthData.hours.flexTimeCorrection)}} h
            </label>
          </div>
          <div class="d-flex flex-row pl-2 pr-2">
            <label id="totalDiffFromExpectedActualHeader"
              class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              {{ extrapolateFlexTime ? 'Vor.' : '' }} Stundensaldo
            </label>
            <label id="totalDiffFromExpectedActual" class="black--text subtitle-1 justify-end">
              <span v-if="extrapolateFlexTime">{{makeNumberSigned(monthData.hours.totalDiffExtrapolated)}}</span>
              <span v-else>{{makeNumberSigned(monthData.hours.totalDiff)}}</span> h
            </label>
          </div>

          <v-divider class="pl-2 pr-2"></v-divider>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="flexTimeNewTotalHeader"
              class="d-flex flex-grow-1 black--text subtitle-1 justify-start font-weight-bold">
              {{ extrapolateFlexTime ? 'Vor. ' : '' }} Endstand {{monthData.name}}
            </label>
            <label id="flexTimeNewTotal" class="black--text subtitle-1 justify-end font-weight-bold">
              <span v-if="extrapolateFlexTime">
                {{monthData.hours.flexTimeBalanceExtrapolated != undefined ? makeNumberSigned(monthData.hours.flexTimeBalanceExtrapolated) : '-'}} h
              </span>
              <span v-else>
                {{monthData.hours.flexTimeBalance != undefined ? makeNumberSigned(monthData.hours.flexTimeBalance) : '-'}} h
              </span>
            </label>
          </div>
        </v-card>
      </v-col>

      <v-col>
        <v-card v-if="monthData.holidays" id="holidayBlock" style="flex-basis: 100px;"
          class="d-flex flex-column pl-2 pt-2 pb-2 ml-2 mt-2 mb-2 flex-grow-1">
          <label id="holidayBlockHeader" class="title pa-2 mb-2 text-center grey--text">Urlaubskonto</label>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="holidaysAtStartOfMonthHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Stand Vormonat
            </label>
            <label id="holidaysAtStartOfMonth" class="black--text subtitle-1 justify-end">
              {{monthData.holidays.atStartOfMonth}} Tag(e)
            </label>
          </div>
          <div class="d-flex flex-row pl-2 pr-2">
            <label id="holidayCorrectionHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Manuelle Korrektur
            </label>
            <label id="holidayCorrection"
              class="black--text subtitle-1 justify-end">{{makeNumberSigned(monthData.holidays.correction)}}
              Tag(e)</label>
          </div>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="consumedHolidaysHeader" class="d-flex flex-grow-1 black--text subtitle-1 justify-start">
              Konsumiert/Geplant</label>
            <label id="consumedHolidays" class="black--text subtitle-1 justify-end">
              {{makeNumberSigned(monthData.holidays.consumedThisMonth, '', true)}}/{{makeNumberSigned(monthData.holidays.plannedInMonth, '', true)}} Tag(e)
            </label>
          </div>

          <v-divider class="pl-2 pr-2"></v-divider>

          <div class="d-flex flex-row pl-2 pr-2">
            <label id="remainingHolidaysHeaderPageBottom"
              class="d-flex flex-grow-1 black--text subtitle-1 justify-start font-weight-bold">
              Resturlaub (Heute/Vor. Ende {{monthData.name}})</label>
            <label id="remainingHolidaysPageBottom" class="black--text subtitle-1 justify-end font-weight-bold">
              {{monthData.holidays.remainingBalance}}/{{monthData.holidays.atEndOfMonth}} Tag(e)
            </label>
          </div>
        </v-card>
      </v-col>
      <v-col cols="0" md="1"></v-col>
    </v-row>
    <!-- /Zusammenfassung -->
    
    <v-snackbar id="errorMessage" v-if="error.show" v-model="error.show" color="red accent-2" timeout="5000" top app centered elevation="6">
      {{ error.message }}
    </v-snackbar>
  </section>
</template>

<script lang="js">
import dayjs from '@util/dayjs';

import FileManager from '@components/FileManager.vue';
import UserWeek from '@components/user/UserWeek.vue';
import ProjectActivities from '@components/user/ProjectActivities.vue';
import * as monatsabschlussClient from '@client/client.monatsabschluss';
import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue';

export default {
  name: 'MonthTable',
  components: { UserWeek, ProjectActivities, FileManager },
  mixins: [StatusColorCalculator],
  props: ['monthData', 'attachments', 'entryUpdateEnabled'],
  
  created() {
    this.expandWeeks();
  },

  data() {
    return {
      expandedWeeks: [],
      selfReference: this,
      fileUploadLoading: false,
      fileDownloadLoadingIDs: [],
      fileDeleteLoadingIDs: [],
      error: { 
        show: false,
        message: ''
      }
    }
  },

  computed: {
    monthActivities: function() {
      if (this.monthData?.weeks) {
        const monthActivities = this.monthData.weeks.flatMap(week => {
          return week.days;
        }).flatMap(day => {
          return day.activities;
        });

        return monthActivities;
      } else {
        return [];
      }
    },

    monthPresences: function() {
      if (this.monthData?.weeks) {
        const monthPresences = this.monthData.weeks.flatMap(week => {
          return week.days;
        }).flatMap(day => {
          return day.presences;
        });

        return monthPresences;
      } else {
        return [];
      }
    },

    isFlextimeForecast: function() {
      return (this.monthData.closing?.status === global.monthClosing.status.prevMonthOpen || this.monthData.closing?.status === global.monthClosing.status.future)
        && this.monthData.hours.flexTimeLastMonth != undefined;
    },

    displayedDate: function() {
      return this.$store.state.displayedMonth;
    },

    extrapolateFlexTime: function () {
      return this.$store.getters.otherSettings.extrapolateFlexTime &&
        this.displayedDate.isoMonth() >= dayjs().isoMonth() &&
        this.isMonatsabschluss === false;
    },

    isMonatsabschluss: function() {
      return this.$route.path.startsWith('/monatsabschluss') ||
        (
          this.monthData.closing.status !== global.monthClosing.status.rejected &&
          this.monthData.closing.status !== global.monthClosing.status.prevMonthOpen &&
          this.monthData.closing.status !== global.monthClosing.status.open
        );
    },

    fileManipulationEnabled: function () {
      if (this.entryUpdateEnabled == false || this.displayedDate.isAfter(dayjs())) {
        return false;
      }

      switch (this.monthData.closing?.status) {
        case global.monthClosing.status.open:
        case global.monthClosing.status.rejected:
        case global.monthClosing.status.prevMonthOpen: 
          return true;
        default:
          return false;
      }
    }
  },

  methods: {
    expandWeeks() {
      this.expandedWeeks = [];
      for (let i = 0; i < this.monthData.weeks?.length; i++) {
        const week = this.monthData.weeks[i];

        if (( /*week.status == 'warning' ||*/ week.status == 'error') || ((week.calendarWeek == dayjs().isoWeek()) && (
          this.displayedDate.isoMonth() == dayjs().isoMonth()))) {
          this.$set(this.expandedWeeks, i, i);
        }
      }
    },

    expandAllWeeks() {
      this.expandedWeeks = [];
      for (let i = 0; i < this.monthData.weeks?.length; i++) {
        this.$set(this.expandedWeeks, i, i);
      }
    },

    collapseAllWeeks() {
      this.expandedWeeks = [];
      this.$emit('collapseAllDays');
    },

    expandAllDays() {
      this.$emit('expandAllDays');
      this.expandAllWeeks();
    },

    collapseAllDays() {
      this.$emit('collapseAllDays');
    },

    async addAttachments(files) {
      this.fileUploadLoading = true;

      const result = await monatsabschlussClient.addAttachments(this.displayedDate, files);
      if (result != undefined) {
        const staticUserData = this.$store.getters.staticUserData;
        await monatsabschlussClient.clearCacheSpecificRoute(`?reporterEmail=${encodeURIComponent(staticUserData.user.email)}&month=${this.displayedDate.isoMonth()}&year=${this.displayedDate.year()}`);

        this.$root.$emit('reload');
      } else {
        this.error.show = true;
        this.error.message = 'Fileupload fehlgeschlagen!';
      }
      
      this.fileUploadLoading = false;
    },

    async deleteAttachment(attachment) {
      this.fileDeleteLoadingIDs.push(attachment.id);

      const result = await monatsabschlussClient.deleteAttachment(this.displayedDate, attachment.id);
      if (result != undefined) {
        const staticUserData = this.$store.getters.staticUserData;
        await monatsabschlussClient.clearCacheSpecificRoute(`?reporterEmail=${encodeURIComponent(staticUserData.user.email)}&month=${this.displayedDate.isoMonth()}&year=${this.displayedDate.year()}`)
  
        this.$root.$emit('reload');
      } else {
        this.error.show = true;
        this.error.message = 'Löschen fehlgeschlagen!';
      }

      this.fileDeleteLoadingIDs = this.fileDeleteLoadingIDs.filter(id => id != attachment.id);
    },

    async downloadAttachment(attachment) {
      this.fileDownloadLoadingIDs.push(attachment.id);

      const file = await monatsabschlussClient.getAttachment(attachment.url);
      if (file) {
        const blob = new File([file], attachment.filename, { type: attachment.type });
        const url = window.URL.createObjectURL(blob); 

        const link = document.createElement("a");
        link.href = url;
        link.download = attachment.filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.error.show = true;
        this.error.message = 'Download fehlgeschlagen!';
      }

      this.fileDownloadLoadingIDs = this.fileDownloadLoadingIDs.filter(id => id != attachment.id);
    }
  }
}
</script>
<style lang="scss">
.v-expansion-panel--active
  > section
  > .v-expansion-panel-header--active
  .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
  .v-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
</style>