global.isProduction = process.env.NODE_ENV === 'production' && process.env.VUE_APP_ADDITIONAL_APP_TITLE === '';
global.isDevelopment = process.env.NODE_ENV === 'development';
global.isE2ETest = process.env.VUE_APP_E2E == 'true' || (typeof window !== 'undefined' && window.Cypress); // wenn man nicht mit 'typeof' checkt bekommt man "ReferenceError: window is not defined"

global.LOCAL_STORAGE_ENV = process.env.VUE_APP_LOCAL_STORAGE_ENV;
global.ADDITIONAL_APP_TITLE = process.env.VUE_APP_ADDITIONAL_APP_TITLE;

global.SEQITRACKER_SERVER_URL = process.env.VUE_APP_SEQITRACKER_SERVER_URL;
global.MOCO_SERVER_URL = process.env.VUE_APP_MOCO_SERVER_URL;
global.JIRA_SERVER_URL = process.env.VUE_APP_JIRA_SERVER_URL;
global.JIRA_PROJECT_KEY = process.env.VUE_APP_JIRA_PROJECT_KEY;
global.MOCO_IMPERSONATION_KEY = process.env.VUE_APP_MOCO_IMPERSONATION_KEY;

global.SEQITRACKER_API_URL = global.SEQITRACKER_SERVER_URL + '/api/v1/seqiTracker';
global.MOCO_API_URL = global.MOCO_SERVER_URL + '/api/v1';

global.MOCO_TASK_IDS = JSON.parse(process.env.VUE_APP_MOCO_TASKS || '{}');
global.DEFAULT_INTERNAL_PROJECTS = JSON.parse(process.env.VUE_APP_DEFAULT_INTERNAL_PROJECTS ?? '[]').map(project => project.id);

// TODO auch den Namen der Router hier definieren! Evtl auch mit '/' davor?
global.ROUTES = {
  root: {
    path: '/',
  },
  aktuellermonat: {
    path: '/aktuellermonat',
  },
  monatsansicht: {
    path: '/monatsansicht',
    name: 'Month'
  },
  monatsabschluss: {
    path: '/monatsabschluss',
    name: 'ClosedMonth'
  },
  projekt: {
    path: '/projektansicht',
    name: 'Project'
  },
  einstellungen: {
    path: '/einstellungen',
  },
  qa: {
    path: '/qa',
  },
  hr: {
    path: '/hr',
    name: 'HR',
    createEmployee: {
      path: '/createEmployee',
      name: 'CreateEmployee'
    },
    employees: {
      path: '/employees',
      name: 'HREmployees'
    },
  },
  gl: {
    path: '/gl',
    name: 'GL',
    employees: {
      path: '/employees',
      name: 'GLEmployees'
    },
  },
  teamlead: {
    path: '/teamlead',
    name: 'Teamlead'
  },
  admin: {
    path: '/admin',
  }
};

// ACHTUNG: nicht ändern, die müssen gleich dem Status im Jira heissen
global.monthClosing = {
  status: {
    open: 'Open',
    approved: 'Approved',
    rejected: 'Corrections Needed',
    waitingForTL: 'Waiting for Teamlead approval',
    waitingForHR: 'Waiting for HR approval',
    prevMonthOpen: 'Vorheriges Monat noch nicht vollständig abgeschlossen',
    future: 'Die Zukunft ist weder vorherseh- noch abschließbar :)'
  },
  transition: {
    approveTL: 'approve (tl)',
    approveHR: 'approve (hr)',
    fixed: 'fixed',
    reject: 'reject',
    backToTL: 'back to teamlead'
  }
};


global.projectClosing = {
  status: {
    noActivities: 'keine Leistungszeiten',
    open: 'Open',
    readyToSettle: 'Freigabe zur Verrechnung',
    draft: 'Verrechnet (Entwurf)',
    settled: 'Verrechnet',
    inClarification: 'In Clarification'
  },
  transition: {
    clarify: 'rueckfrage',
    resubmit: 'erneute freigabe',
    draft: 'entwurf fertig',
    settle: 'verrechnet',
    cancel: 'storno'
  }
};

global.availableRules = {
  user: ['apply_month_closing', 'w_longer_break', 'w_weekend_holiday_work', 'w_no_ho_days', 'w_15min_round', 'w_extra_charge'],
  gl: ['apply_month_closing', 'w_longer_break', 'w_weekend_holiday_work', 'w_no_ho_days', 
    'w_15min_round', 'w_10h', 'w_no_description', 'w_0h_entry', 'w_no_break', 'w_no_entries', 
    'e_12h', 'e_different', 'w_minimum_rest']
}

global.maxFileSize = (15 * 1024 * 1024); // 15MB
global.maxTotalFileSize = global.maxFileSize * 3;

global.impersonationID = undefined;