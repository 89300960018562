const AUTHENTICATION_KEY = 'authentication';

const setAuthentication = function(email, mocoUserID, mocoApiKey, jwt) {
  setItem(AUTHENTICATION_KEY, {email, mocoUserID, mocoApiKey, jwt});
}

const addSQTJWT = function(jwt) {
  const authentication = getAuthentication();
  authentication.jwt = jwt;
  setItem(AUTHENTICATION_KEY, authentication);
}

const getAuthentication = function() {
  return getItem(AUTHENTICATION_KEY) ?? {};
}

const clearAuthentication = function (keepEmail) {
  setAuthentication(keepEmail ? getAuthentication().email : null, null, null, null);
}

const setItem = function (key, value) {
  try {
    const currentValue = JSON.parse(localStorage.getItem(key) ?? `{"${global.LOCAL_STORAGE_ENV}":{}}`);
    currentValue[global.LOCAL_STORAGE_ENV] = value;
    localStorage.setItem(key, JSON.stringify(currentValue));
  } catch (e) {
    localStorage.setItem(key, null);
  }
}

const getItem = function (key) {
  const value = localStorage.getItem(key);
  try {
    return value ? JSON.parse(value)[global.LOCAL_STORAGE_ENV] : null;
  } catch (e) {
    localStorage.removeItem(key);
    return null;
  }
}


export default {
  setAuthentication,
  getAuthentication,
  clearAuthentication,
  addSQTJWT,
  setItem,
  getItem
}