<template>
  <section>
    <v-overlay :value="this.showTeamDataLoadingOverlay" absolute />
    <v-card flat>
      <Flicker :loading="this.loading" @changeRoute="this.changeRoute">
      </Flicker>

      <section class="monthTable">
        <br><br>
        <v-row class="justify-center">
          <v-col cols="auto" md="10">
            <v-slide-y-transition>
              <!-- CR EmployeeTable props: ['tableData'] -->
              <EmployeeTable v-if="this.loading == false" :tableData="this.teamTableData"></EmployeeTable>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </section>
    </v-card>
  </section>
</template>

<script lang="js">
import * as teamleadClient from '@client/client.teamlead';
import Flicker from '@components/Flicker.vue';
import EmployeeTable from '@components/employees/EmployeeTable.vue';

export default {
  name: 'Teamlead',
  components: {
    Flicker,
    EmployeeTable
  },

  created() {
    this.$root.$on('startLoading', this.startLoading);
    this.$root.$on('reload', this.reloadTeamData);
  },

  mounted() {
    this.initialLoadTeamData();
  },

  beforeDestroy() {
    this.$root.$off('startLoading', this.startLoading);
    this.$root.$off('reload', this.reloadTeamData);
  },

  data() {
    return {
      loading: false,
      teamTableData: []
    }
  },

  computed: {
    showTeamDataLoadingOverlay: function () {
      return this.loading && this.initialTeamDataLoad == false;
    },

    displayedDate: function () {
      return this.$store.state.displayedMonth;
    },
  },

  methods: {
    startLoading() {
      this.loading = true;
    },

    async initialLoadTeamData() {
      this.initialTeamDataLoad = true;
      await this.loadTeamData();
      this.initialTeamDataLoad = false;
    },

    async reloadTeamData() {
      await teamleadClient.clearCache();
      await this.loadTeamData();
    },

    async loadTeamData() {
      this.loading = true;
      const teamData = await this.$draf(async () => {
        return await teamleadClient.getTeamData(this.displayedDate.year(), this.displayedDate.isoMonth());
      });

      this.teamTableData = teamData;

      this.loading = false;
    },

    changeRoute(displayedDate) {
      this.$router.push({
        name: global.ROUTES.teamlead.name,
        params: {
          year: displayedDate.year(),
          month: displayedDate.isoMonth()
        }
      });
    },
  },

  watch: {
    displayedDate: function () {
      this.initialLoadTeamData();
    }
  },

}
</script> 
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>