<script lang="js">
import dayjs from '@util/dayjs';
export default {
  render: function () {
    return undefined; // Workaround, damit das mixin ohne Warning testbar ist
  },
  data() {
    return {
      formRules: {
        required: [
          v => (v != undefined && v.length !== 0) || 'Feld muss befüllt werden',
        ],
        string: [
          v => (v == undefined || typeof v === 'string' && (v === '' || v.trim().length > 0)) || 'Feld darf nicht nur aus Leerzeichen bestehen',
        ],
        number: [
          v => (v != undefined && isNaN(v) == false) || 'Eingabe muss eine Zahl sein!',
        ],
        positiveNumber: [
          v => (v != undefined && isNaN(v) == false) || 'Eingabe muss eine Zahl sein!',
          v => (v != undefined && v >= 0) || 'Zahl darf nicht negativ sein!'
        ],
        email: [
          v => (v != undefined && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Email Format nicht korrekt'
        ],
        date: [
          v => dayjs(v, 'DD.MM.YYYY').format('DD.MM.YYYY') === v || 'Datum nicht gültig! (DD.MM.YYYY)'

          // ODER Variante wo man je nachdem ob das Format ungültig ist oder das Datum wirklich nicht
          // exisitert unterschiedliche Fehlermeldungen bekommt
          // v => {
          //   const date = dayjs(v, 'DD.MM.YYYY');
          //   if(date.isValid()) {
          //     return  date.format('DD.MM.YYYY') === v || 'Format ist nicht korrekt (DD.MM.YYYY)';
          //   }
          //   return 'Datum existiert nicht!';
          // }
        ]
      }
    }
  },

  methods: {
    combineRules(...rules) {
      return [].concat(...rules);
    }
  }
};
</script>