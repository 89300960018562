<template lang="html">
  <section class="rules">
    <v-row dense>

      <v-col cols="12" data-cy="regelnDeaktivieren">
        <v-card>
          <v-card-title>
            Regeln aktivieren
          </v-card-title>
          <v-card-text>
            <v-switch inset @change="onSwitchChange()" color="#C00000" v-model="allSwitch"
              label="Alle Regeln"/>

            <div :key="allSwitch">
              <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_longer_break"
                  label="Mehr als 30 Minuten Pause"/>
              <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_weekend_holiday_work"
                  label="An Feiertagen oder am Wochenende gearbeitet"/>
              <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_15min_round"
                  label="Arbeits- oder Leistungszeit nicht auf 15 Minuten gerundet"/>
              <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_no_ho_days"
                  label="Monat hat keine Homeofficetage"/>
              <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_extra_charge"
                  label="Verrechenbaren Task mit Aufschlag vergessen (vor 8:00 bzw. nach 18:00 und an Wochenende/Feiertag)"/>

              <div id="gl-switches" v-if="isGL">
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_10h"
                  label="10 Stunden Haben überschritten"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_no_description"
                  label="Leistungszeit Eintrag hat keine Beschreibung"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_0h_entry"
                  label="Leistungszeit Eintrag hat eine Dauer von 0 Stunden"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_no_break"
                  label="> 6 Stunden gearbeitet, aber keine 30 min. Pause vorhanden"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_no_entries"
                  label="Keine Zeiten gebucht"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.e_12h"
                  label="12 Stunden Haben überschritten"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.e_different"
                  label="Arbeits- und Leistungszeit unterschiedlich"/>
                <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.w_minimum_rest"
                  label="Minimale Ruhezeit unterschritten"/>
              </div>
            </div>
            <v-divider></v-divider>
            <v-switch dense @change="onSwitchChange()" color="#C00000" v-model="enabledRules.apply_month_closing"
              label="Einstellungen für Monatsabschluss-Ansicht übernehmen"/>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" data-cy="sonstigeEinstellungen">
        <v-card>
          <v-card-title>
            Sonstige Einstellungen
          </v-card-title>
          <v-card-text>
            <v-switch data-cy="extrapoliereGleitzeitkonto" dense @change="$store.dispatch('setOtherSettings', otherSettings);" color="#C00000"
              v-model="otherSettings.extrapolateFlexTime" label="Extrapoliere Gleitzeitkonto"/>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </section>
</template>

<script lang="js">

  export default {
    name: 'Settings',
    props: [],
    data() {
      return {
        otherSettings: this.$store.getters.otherSettings,
        enabledRules: this.$store.getters.enabledRules,
      }
    },
    

    computed: {
      isGL: function () {
        return this.$store.getters.staticUserData.user.roles.isGL;
      },
      allSwitch: { 
        get: function () {
          for (const [ruleName, ruleState] of Object.entries(this.enabledRules)) {
            if (ruleState === false && ruleName != 'apply_month_closing') {
              return false;
            }
          }
          return true;
        },

        set: function(state) {
          for (const ruleName of Object.keys(this.enabledRules)) {
            if (ruleName != 'apply_month_closing') {
              this.enabledRules[ruleName] = state;
            }
          }
        }
      }
    },

    created() {
    },

    methods: {
      onSwitchChange() {
        this.$store.dispatch('setEnabledRules', this.enabledRules);
      },

    }
  }
</script>