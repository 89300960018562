<template>
  <v-card flat>
    <v-form v-model="valid" ref="form" lazy-validation>
      <div class="d-flex align-center justify-center">
        <v-dialog v-model="dialog" persistent max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mt-2" fab small depressed v-bind="attrs" v-on="on" id="activator">
              <v-icon dark>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <v-card flat>
            <v-card-title class="pb-0">
              <v-avatar class="my-2 mr-4">
                <img id="avatar" alt="Avatar" :style="employeeToUpdate.isStillEmployed ? '' : 'opacity: 0.5;'"
                  :src="employeeToUpdate.avatar_url ? employeeToUpdate.avatar_url : require('../../assets/avatar_placeholder.png')">
              </v-avatar>
              <label id="name">
                {{ employeeToUpdate.fullname.firstname + ' ' + employeeToUpdate.fullname.lastname }}
              </label>
            </v-card-title>
            <!-- <v-divider class="mx-6 mb-4"></v-divider> -->
            <v-card-text class="pb-0 pt-0 ">
              <v-container class="pb-2">
                <v-list>
                  <v-list-item>
                    <v-list-item-content class="py-0">

                      <v-row>
                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <h4>Vorname</h4>
                          </v-list-item-title>
                          <v-text-field autofocus id="firstname" class="ma-0 pa-0" label="" v-model="firstname" ref="firstname"
                            :rules="combineRules(formRules.required, formRules.string)"></v-text-field>
                        </v-col>
                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <h4>Nachname </h4>
                          </v-list-item-title>
                          <v-text-field id="lastname" class="ma-0 pa-0" label="" v-model="lastname" ref="lastname"
                            :rules="combineRules(formRules.required, formRules.string)"></v-text-field>
                        </v-col>
                      </v-row>

                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-row>

                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <b>Adresse </b>
                          </v-list-item-title>
                          <v-textarea :auto-grow="true" rows="1" id="address" class="ma-0 pa-0" label="" v-model="address"
                            ref="address" :rules="formRules.string"></v-textarea>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-row>
                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <h4>Eintrittsdatum</h4>
                          </v-list-item-title>
                          <v-text-field id="employedSince" class="ma-0 pa-0" placeholder="DD.MM.YYYY" ref="employedSince"
                            :value="employedSince.textfield"
                            :rules="combineRules(formRules.required, formRules.date)"
                            @input="updateEmployedSinceDateValue($event)">
                            <template v-slot:append>
                              <v-menu ref="employedSinceMenu" v-model="employedSince.picker" :close-on-content-click="false"
                                transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" class="mt-1 grow-icon" size="20">mdi-calendar</v-icon>
                                </template>
                                <v-date-picker color="#c00000" no-title scrollable v-model="employedSince.date"
                                  @input="updateEmployedSinceTextfieldValue($event)" />
                              </v-menu>
                            </template>
                          </v-text-field>

                        </v-col>
                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <h4>Geburtstag</h4>
                          </v-list-item-title>
                          <v-text-field id="birthday" class="ma-0 pa-0" placeholder="DD.MM.YYYY" ref="birthday"
                            :value="birthday.textfield" 
                            :rules="combineRules(formRules.required, formRules.date)"
                            @input="updateBirthdayDateValue($event)">
                            <template v-slot:append>
                              <v-menu ref="birthdayMenu" v-model="birthday.picker" :close-on-content-click="false"
                                transition="slide-y-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" class="mt-1 grow-icon" size="20">mdi-calendar</v-icon>
                                </template>
                                <v-date-picker color="#c00000" no-title scrollable v-model="birthday.date"
                                  @input="updateBirthdayTextfieldValue($event)" />
                              </v-menu>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content class="py-0">
                      <v-row>
                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <b>Überstundenpauschale </b>
                          </v-list-item-title>
                          <v-text-field id="overTimeFlat" type="number" min="0" class="ma-0 pa-0" label=""
                            :rules="combineRules(formRules.required, formRules.positiveNumber)"
                            v-model.number="overTimeFlat"></v-text-field>
                        </v-col>
                        <v-col class="py-2">
                          <v-list-item-title class="text-overline mb-1">
                            <b>Monatliche GZK Korrektur </b>
                          </v-list-item-title>
                          <v-text-field id="monthlyFlexTimeCorrection" type="number" class="ma-0 pa-0" label=""
                            :rules="combineRules(formRules.required, formRules.number)"
                            v-model.number="monthlyFlexTimeCorrection"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>

                  </v-list-item>
                </v-list>
              </v-container>
            </v-card-text>
            <v-card-actions class="pb-4 pr-1">
              <v-btn id="resetForm" type="button" @click="initializeForm()" outlined :loading="loading"
                color="#c00000">zurücksetzen
              </v-btn>
              <v-btn id="resetForm" type="button" @click="endEmployment()" outlined :loading="loading"
                color="#c00000" :disabled="this.employedUntil != null">{{ this.employedUntil == null ? 'Austritt' : `Austritt am ${formatDate(this.employedUntil)}` }}
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn id="closeButton" type="button" color="#c00000" outlined @click="dialog = false" :loading="loading">
                Schließen
              </v-btn>
              <v-btn id="sendButton" color="#c00000" class="mr-3 white--text" @click="updateUser()" :loading="loading"
                :disabled="!valid">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-form>

    <v-snackbar id="errorMessage" v-if="errorHandling.showError" v-model="errorHandling.showError" bottom
      color="red accent-2">
      <v-progress-circular indeterminate color="white"> </v-progress-circular>
      {{ errorHandling.errorMessage }}
    </v-snackbar>

    <EndUserEmploymentDialog ref="endUserEmploymentDialog"></EndUserEmploymentDialog>
  </v-card>
</template>

<script>
import * as hrClient from '@client/client.hr';
import FormRules from '@mixins/FormRules.vue';
import EndUserEmploymentDialog from '@components/HR/EndUserEmploymentDialog.vue';
import dayjs from '@util/dayjs';

export default {
  name: 'UpdateEmployee',
  components: {
    EndUserEmploymentDialog
  },
  mixins: [FormRules],
  props: ['employeeToUpdate'],

  data() {
    return {
      firstname: null,
      lastname: null,
      address: null,

      employedSince: {
        textfield: null,
        date: null,
        picker: false
      },
      employedUntil: null,
      birthday: {
        textfield: null,
        date: null,
        picker: false
      },

      overTimeFlat: null,
      monthlyFlexTimeCorrection: null,

      errorHandling: {
        showError: false,
        errorMessage: ''
      },
      
      dialog: false,
      loading: false,
      valid: true
    }
  },

  methods: {
    initializeForm() {
      this.firstname = this.employeeToUpdate.fullname.firstname;
      this.lastname = this.employeeToUpdate.fullname.lastname;
      this.address = this.employeeToUpdate.address;
      this.employedSince.textfield = this.employeeToUpdate.employedSince ? dayjs(this.employeeToUpdate.employedSince, 'YYYY-MM-DD').format('DD.MM.YYYY') : null;
      this.employedSince.date = this.employeeToUpdate.employedSince;
      this.employedUntil = this.employeeToUpdate.employedUntil;
      this.birthday.textfield = this.employeeToUpdate.birthday ? dayjs(this.employeeToUpdate.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY') : null;
      this.birthday.date = this.employeeToUpdate.birthday;
      this.overTimeFlat = this.employeeToUpdate.overTimeFlat ?? 0;
      this.monthlyFlexTimeCorrection = this.employeeToUpdate.monthlyFlexTimeCorrection ?? 0;
      
      this.$draf(() => {
        this.$refs.form.validate();
      });
    },
    
    async updateUser() {
      this.loading = true;

      if (this.$refs.form.validate() == false) {
        this.loading = false;
        return;
      }

      const updatedUser = {
        firstname: this.firstname,
        lastname: this.lastname,
        address: this.address,
        // personnelNumber: this.personnelNumber, // TODO wenn die überhaupt aktualisierbar sein soll, dann muss es auch ein checkIfPersonnelNumberAlreadyExists wie im createEmployee geben

        employedSince: this.employedSince.date,
        birthday: this.birthday.date,

        overTimeFlat: this.overTimeFlat,
        monthlyFlexTimeCorrection: this.monthlyFlexTimeCorrection
      };

      try {
        await hrClient.updateUser(this.employeeToUpdate.id, updatedUser);
      } catch (error) {
        this.loading = false;
        this.errorHandling.showError = true;
        this.errorHandling.errorMessage = `Updaten des Users ist fehlgeschlagen.\n\n Error: ${error.message}`;
        return;
      }
      this.$root.$emit('reloadApp', this); // TODO ACHTUNG: das ist doch eigentlich viel zu viel!

      this.dialog = false;
      this.loading = false;
    },

    updateEmployedSinceDateValue(value) {
      if (this.$refs.employedSince.validate() && dayjs(value, 'DD.MM.YYYY').isValid()) {
        this.employedSince.textfield = value;
        this.employedSince.date = dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
    },

    updateEmployedSinceTextfieldValue(value) {
      this.employedSince.date = value;
      this.employedSince.textfield = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.employedSince.picker = false;
    },

    updateBirthdayDateValue(value) {
      if (this.$refs.birthday.validate() && dayjs(value, 'DD.MM.YYYY').isValid()) {
        this.birthday.textfield = value;
        this.birthday.date = dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }
    },

    updateBirthdayTextfieldValue(value) {
      this.birthday.date = value;
      this.birthday.textfield = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
      this.birthday.picker = false;
    },

    async endEmployment() {
      const confirmation = await this.$refs.endUserEmploymentDialog.open();
      if (confirmation.confirmed && confirmation.endDate) {
        this.loading = true;
        try {
          await hrClient.endUserEmployment(this.employeeToUpdate.id, confirmation.endDate, confirmation.exemptFrom);
        } catch (error) {
          this.loading = false;
          this.errorHandling.showError = true;
          this.errorHandling.errorMessage = `Beendigung des Dienstverhältnisses ist fehlgeschlagen.\n\n Error: ${error.message}`;
          return;
        }
        this.$root.$emit('reloadApp', this); // TODO ACHTUNG: das ist doch eigentlich viel zu viel!

        this.dialog = false;
        this.loading = false;
      }
    }
  },

  watch: {
    dialog: function () {
      if (this.dialog) {
        this.initializeForm(); // hier im watch notwendig, damit bei der Anzeige des Dialogs gleich die fehlenden Pflichtfelder markiert werden
      }
    }
  }
}
</script>