<!--https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d-->
<template>
  <v-dialog v-model="visible" max-width="420" :style="{ zIndex: '200' }" @keydown.esc="cancel" persistent>
    <v-card>
      <v-card-title class="pl-4">
        Beendigung des Dienstverhältnisses
      </v-card-title>

      <v-card-text class="pl-4 pr-4 pb-0">
        <v-list>
          <v-list-item>
            <v-list-item-content class="py-0">

              <v-col class="py-2">
                <v-list-item-title class="text-overline mb-1">
                  <b>Austrittsdatum </b>
                </v-list-item-title>

                <v-menu ref="employmentMenu" v-model="employment.menu" :close-on-content-click="false"
                    transition="scale-transition" offset-y ax-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="employment.endDate" readonly prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" class="mt-0 pt-0"/>
                  </template>
                  <v-date-picker v-model="employment.endDate" no-title scrollable @input="employment.menu = false"/>
                </v-menu>
              </v-col>


              <v-col class="py-2">
                <v-list-item-title class="text-overline mb-1">
                  <b>Freistellung </b>
                </v-list-item-title>

                <v-checkbox color="#c00000" v-model="exempt.doExempt" label="Freistellungsdatum setzen"></v-checkbox>
                <v-menu ref="exemptMenu" v-model="exempt.menu" :close-on-content-click="false"
                    transition="scale-transition" offset-y ax-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="exempt.from" readonly prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" :disabled="!exempt.doExempt" 
                      :rules="[isExemptDateValid]"
                      class="mt-0 pt-0"/>
                  </template>
                  <v-date-picker v-model="exempt.from" no-title scrollable @input="exempt.menu = false"/>
                </v-menu>
              </v-col>

            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="pt-2">
        <v-spacer></v-spacer>
        <v-btn type="button" @click.native="cancel()" color="#c00000" outlined>Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn type="button" @click.native="confirm()" color="#c00000" class="white--text" :disabled="isConfirmEnabled">Austritt bestätigen</v-btn>
      </v-card-actions>
    </v-card>
    
    <Confirm ref="endWithExempt" confirmButtonText="Ja" cancelButtonText="Abbrechen"></Confirm>
  </v-dialog>
</template>

<script>
import Confirm from '@components/Confirm.vue';
import dayjs from '@util/dayjs';

export default {
  components: {
    Confirm
  },

  data: () => ({
    visible: false,
    employment: {
      menu: false,
      endDate: dayjs().format('YYYY-MM-DD')
    },
    exempt: {
      menu: false,
      doExempt: false,
      from: null
    },
    result: {
      resolve: null,
      reject: null,
    }
  }),

  computed: {
    isEndAfterExemptDate: function () {
      if (this.employment.endDate && this.exempt.from) {
        return dayjs(this.employment.endDate).isAfter(dayjs(this.exempt.from));
      }
      return true;
    },

    isConfirmEnabled: function () {
      return (this.isExemptDateValid() == false || this.exempt.from == undefined) && this.exempt.doExempt;
    }
  },

  methods: {
    open() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.result.resolve = resolve;
        this.result.reject = reject;
      })
    },

    async confirm() {
      const message = `Es werden automatisch Zeiten mit "Bezahlter Abwesenheit" erstellt (${this.formatDate(this.exempt.from)} - ${this.formatDate(this.employment.endDate)}), das kann nicht mehr (einfach) rückgängig gemacht werden!`;
      if ((this.exempt.doExempt == false) || (this.exempt.doExempt && await this.$refs.endWithExempt.open('Mitarbeiter freistellen', message))) {
        this.result.resolve({
          confirmed: true,
          endDate: this.employment.endDate,
          exemptFrom: this.exempt.doExempt ? this.exempt.from : null
        });
        this.visible = false;
      }
    },
    
    cancel() {
      this.result.resolve({
        confirmed: false
      });
      this.visible = false;
    },

    isExemptDateValid() {
      return this.isEndAfterExemptDate;
    }
  }
}
</script>