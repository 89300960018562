<script lang='js'>
  import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue'
  import dayjs from '@util/dayjs';
  import _ from 'lodash';
  import { statusCalculator } from 'seqitracker-util';

  export default {
    mixins: [StatusColorCalculator],
    render: function() {
      return undefined; // Workaround, damit das mixin ohne Warning testbar ist
    },
    data() {
      return {
        structureDefinition: {
          project: {
            filter: {
              path: 'project.id',
              value: 'id'
            },
            userFilter: {
              allOptions: [],
              currentOptions: [],
              selected: undefined
            },
            name: 'project',
            displayName: 'Projekt',
            key: 'id',
            sortBy: 'name',
            getNodeName: (dataset) => dataset.name,
            getFilterValueForActivity: (activity) => activity.project.name,
            columns: [{
              label: () => 'Projekt',
              data: (dataset) => dataset.name
            }, {
              label: () => 'Summe',
              data: (dataset) => this.formattedSum(dataset)
            }, {
              label: () => 'verrechenbar',
              data: (dataset) => dataset.billable
            }]
          },
          task: {
            filter: {
              path: 'task.id',
              value: 'id'
            },
            userFilter: {
              allOptions: [],
              currentOptions: [],
              selected: undefined
            },
            name: 'task',
            displayName: 'Task',
            key: 'id',
            sortBy: 'name',
            getNodeName: (dataset) => dataset.name,
            getFilterValueForActivity: (activity) => activity.task.name,
            columns: [{
              label: () => 'Task',
              data: (dataset) => dataset.name
            }, {
              label: () => 'Summe',
              data: (dataset) => this.formattedSum(dataset)
            }, {
              label: () => 'verrechenbar',
              data: (dataset) => dataset.billable
            }]
          },
          day: {
            filter: {
              path: 'date',
              value: 'date'
            },
            userFilter: {
              allOptions: [],
              currentOptions: [],
              selected: undefined
            },
            name: 'date',
            displayName: 'Tag',
            key: 'date',
            sortBy: 'date',
            getNodeName: (dataset) => dayjs(dataset.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            getFilterValueForActivity: (activity) => dayjs(activity.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
            columns: [{
              label: (dataset) => dataset.publicHoliday ? 'Feiertag' : dayjs(dataset.date, 'YYYY-MM-DD').format('dddd'),
              data: (dataset) => dayjs(dataset.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
            }, {
              label: () => 'Summe',
              data: (dataset) => this.formattedSum(dataset)
            }],
            style: {
              color: (dataset) => {
                const day = dayjs(dataset.date, 'YYYY-MM-DD');
                
                if (dataset.publicHoliday) {
                  return this.getBackgroundColor('publicHoliday');
                } else if (day.day() == dayjs.days.SATURDAY || day.day() == dayjs.days.SUNDAY) {
                  return this.getBackgroundColor('weekend');
                }

                return this.getBackgroundColor('default');
              }
            }
          },
          user: {
            filter: {
              path: 'user.id',
              value: 'id'
            },
            userFilter: {
              allOptions: [],
              currentOptions: [],
              selected: undefined
            },
            name: 'user',
            displayName: 'Person',
            key: 'id',
            sortBy: 'firstname',
            getNodeName: (dataset) => `${dataset.firstname} ${dataset.lastname}`,
            getFilterValueForActivity: (activity) => `${activity.user.firstname} ${activity.user.lastname}`,
            columns: [{
              label: () => 'Person',
              data: (dataset) => `${dataset.firstname} ${dataset.lastname}`
            }, {
              label: () => 'Summe',
              data: (dataset) => this.formattedSum(dataset)
            }]
          }
        },
      }
    },
    methods: {
      formattedSum(dataset) {
        const roundedSum = this.precisionRound(dataset.sum);
        const roundedSumOfDisplayed = this.precisionRound(dataset.sumOfDisplayed);

        if (roundedSumOfDisplayed === undefined || roundedSum === roundedSumOfDisplayed) {
          return `${roundedSum}`;
        } else {
          return `${roundedSumOfDisplayed} (${roundedSum})`
        }
      },

      getTasksByUser(activities) {
        const tasksByUser = this.createActivityTree(activities, [this.structureDefinition.user]);

        for (const user of tasksByUser) {
          user.sum = this.precisionRound(user.sum);
        }

        return tasksByUser;
      },

      createActivityTree(activities, structure) {
        const clonedActivities = _.cloneDeep(activities);
        const structurePart = structure[0];

        //set default values for show and highlightedDescription
        clonedActivities.forEach((activity) => {
          activity.highlightedDescription = activity.description;
          activity.show = true;
        });

        //extract the part of each activity that is relevant for the structure part (e.g. activity.project)
        const structurePartRelevantData = clonedActivities.map((activity) => {
          const structurePartData = activity[structurePart.name];
          if (typeof structurePartData === 'object') {
            return _.cloneDeep(structurePartData);
          } else {
            const structurePartDataObject = {}
            structurePartDataObject[structurePart.name] = structurePartData;
            return structurePartDataObject;
          }
        });

        //use unique instances of structurePartRelevantData as basis for the current node's children
        const childNodes = _.sortBy(_.uniqBy(structurePartRelevantData, structurePart.key), structurePart.sortBy);

        //add new node names to dropdown filter options
        structurePart.userFilter.allOptions = _.uniq([
          ...structurePart.userFilter.allOptions, 
          ...childNodes.map((node) => structurePart.getNodeName(node))
        ]).sort();

        childNodes.forEach((node) => {
          node.activities = _.filter(clonedActivities, [
            structurePart.filter.path,
            node[structurePart.filter.value],
          ]); //verwendet lodash da structurePart.filter.path im format "key1.key2" vorliegt --> js objekt["key"] syntax kann damit nicht umgehen

          //set aggregate properties for node
          node.sum = _.sumBy(node.activities, 'hours');
          node.publicHoliday = this.$store.getters.staticUserData.publicHolidays.includes(node.date);
          node.status = statusCalculator.calcStatus(node.activities);
          node.warnings =  _.uniqWith(_.flatMapDeep(node.activities, 'warnings'), _.isEqual);
          node.errors = _.uniqWith(_.flatMapDeep(node.activities, 'errors'), _.isEqual);
          node.key = structurePart.name + '_' + node[structurePart.key];
          node.show = true;

          //if structure has further levels, call recursively
          if (structure.length > 1) {
            node.childNodes = this.createActivityTree(node.activities, _.tail(structure));
          }
        });

        return childNodes;
      },
      
      applyFiltersToTree(activityTree, structure, descriptionFilterString) {
        const structurePart = structure[0];
        let showParent = false;
        for (let node of activityTree) {
          let sumOfDisplayed = 0;
          //if a filter option is selected that does not match the node name, the node is not shown
          if ((structurePart.userFilter.selected) && (structurePart.userFilter.selected !== structurePart.getNodeName(node))) {
            node.show = false;
          } 
          //if node has children, call recursively
          else if (node.childNodes) {
            node.show = this.applyFiltersToTree(node.childNodes, _.tail(structure), descriptionFilterString);
            sumOfDisplayed = _.sumBy(node.childNodes, 'sumOfDisplayed');
          } 
          //if node is a leaf, apply description filter
          else {
            //if description filter is empty, show node with all activities
            if (descriptionFilterString === '' || descriptionFilterString === null) {
              node.show = true;
              for (let activity of node.activities) {
                activity.show = true;
                activity.highlightedDescription = activity.description;
                sumOfDisplayed += activity.hours;
              }
            } else {
              node.show = false;
              for (let activity of node.activities) {
                //if description contains search string, show activity and highlight description
                if (activity.description.toLowerCase().includes(descriptionFilterString.toLowerCase())) {
                  activity.show = true;
                  activity.highlightedDescription = activity.description.replace(new RegExp(`(${descriptionFilterString})`, 'ig'), `<span class="highlightedText">$1</span>`);
                  sumOfDisplayed += activity.hours;
                } else {
                  activity.show = false;
                }
                //if at least 1 activity is shown, show this node
                node.show = node.show || activity.show;
              }
            }
          }
          this.$set(node, 'sumOfDisplayed', sumOfDisplayed);
          //if at least 1 child node is shown, show parent
          showParent = showParent || node.show;
        }

        return showParent;
      },

      setFilterOptions(activities, structure, descriptionFilterString) {
        //if no filters are in use, show all filter options
        const activeFilters = structure.filter((structurePart) => structurePart.userFilter.selected !== undefined);
        if (activeFilters.length === 0 && (descriptionFilterString === '' || descriptionFilterString === null)) {
          structure.forEach((structurePart) => {
            structurePart.userFilter.currentOptions = structurePart.userFilter.allOptions;
          });
          return;
        }

        //object that holds filter options for all filters
        const combinedFilterOptions = {};
        structure.forEach((structurePart) => {
          combinedFilterOptions[structurePart.name] = [];
        });

        activities.forEach((activity) => {
          //if description filter does not match activity, don't add filter options for it
          if (descriptionFilterString && (activity.description?.toLowerCase().includes(descriptionFilterString.toLowerCase()) === false) ) {
            return;
          }

          //for each filter
          structure.forEach((structurePart) => {
            const currentFilterName = structurePart.name;
            let keep = true;
            //apply all filters except the current filter
            structure.forEach((structurePart) => {
              const selectedFilter = structurePart.userFilter.selected;
              if ((structurePart.name !== currentFilterName) && (selectedFilter != undefined)) {
                const nodeName = structurePart.getFilterValueForActivity(activity);
                keep = keep && (selectedFilter === nodeName);
              }
            });

            //if activity is not filtered out, add filter option to current filter
            if (keep) {
              combinedFilterOptions[currentFilterName].push(structurePart.getFilterValueForActivity(activity));
            }
          });
        });

        //set unique filter options
        structure.forEach((structurePart) => {
          structurePart.userFilter.currentOptions = _.uniq(combinedFilterOptions[structurePart.name]).sort();
        });
      },
    },
  };
</script>