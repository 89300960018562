<template lang="html">
  <v-card class="ma-5" flat> 
    <v-tabs id="HRTabs" color="black" icons-and-text>
      <v-tabs-slider color="#c00000"></v-tabs-slider>

      <v-tab class="pa-8" id="employees" key="employees"
        :to="{ path: global.ROUTES.hr.path + global.ROUTES.hr.employees.path }">
        Mitarbeiter anzeigen
        <v-icon>mdi-account-group</v-icon>
      </v-tab>

      <v-tab class="pa-8" id="createEmployee" key="createEmployee"
        :to="{ path: global.ROUTES.hr.path + global.ROUTES.hr.createEmployee.path }">
        Mitarbeiter anlegen
        <v-icon>mdi-account-star</v-icon>
      </v-tab>

      <v-tabs-items>
        <v-divider></v-divider> 
        <router-view>
        </router-view>
      </v-tabs-items>

    </v-tabs>
  </v-card>
</template>

<script lang="js">

export default {
  name: 'HR',
}

</script>