<script lang="js">

  import dayjs from '@util/dayjs';
  import _ from 'lodash';

  import * as monatsabschlussClient from '@client/client.monatsabschluss';

  import { statusCalculator, statusRules } from 'seqitracker-util'

  import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue'

  export default {
    mixins: [StatusColorCalculator],
    render: function() {
      return undefined; // Workaround, damit das mixin ohne Warning testbar ist
    },
    
    methods: {
      fixMonthWithComment() {
        this._fixMonth(true);
      },

      fixMonth() {
        this._fixMonth(false);
      },

      async _fixMonth(showComment) {
        const confirmation = await this.confirmMonthClosing(showComment);
        if (confirmation.confirmed) {
          this.changeMonthStatus(global.monthClosing.transition.fixed, confirmation.comment);
        }
      },

      approveMonthTL() {
        this.changeMonthStatus(global.monthClosing.transition.approveTL);
      },

      async approveMonthTLWithComment() {
        const confirmation = await this.confirmTransition('Monatsabschluss: Bestätigen');
        if (confirmation.confirmed) {
          this.changeMonthStatus(global.monthClosing.transition.approveTL, confirmation.comment);
        }
      },

      approveMonthHR() {
        this.changeMonthStatus(global.monthClosing.transition.approveHR);
      },

      async approveMonthHRWithComment() {
        const confirmation = await this.confirmTransition('Monatsabschluss: Genehmigen');
        if (confirmation.confirmed) {
          this.changeMonthStatus(global.monthClosing.transition.approveHR, confirmation.comment);
        }
      },

      backToTL() {
        this.changeMonthStatus(global.monthClosing.transition.backToTL, undefined);
      },

      async backToTLWithComment() {
        const confirmation = await this.confirmTransition('Monatsabschluss: Rückfrage Teamlead');
        if (confirmation.confirmed) {
          this.changeMonthStatus(global.monthClosing.transition.backToTL, confirmation.comment);
        }
      },

      rejectMonth() {
        this.changeMonthStatus(global.monthClosing.transition.reject, undefined);
      },

      async rejectMonthWithComment() {
        const confirmation = await this.confirmTransition('Monatsabschluss: Zurückweisen an MA');
        if (confirmation.confirmed) {
          this.changeMonthStatus(global.monthClosing.transition.reject, confirmation.comment);
        }
      },
      
      async confirmTransition(title) {
        return await this.$refs.confTransition.open({title}, {confirmText: 'OK', cancelText: 'ABBRECHEN'}, {showComment: true});
      },

      async changeMonthStatus(transition, comment) {
        this.loading = true;

        await this.$draf(async () => {
          const monthDataClosing = transition === global.monthClosing.transition.fixed ? this.buildPayload() : undefined;

          try {
            await monatsabschlussClient.transition(this.monthData.closing.jiraKey, transition, comment, monthDataClosing);
          } catch (err) {
            this.handleError(err);
          }
        });

        this.loading = false;
        
        this.$root.$emit('reloadApp');
      },

      buildPayload() {
        const staticUserData = this.$store.getters.staticUserData;

        /*
         ACHTUNG: jede Änderung hier bedeutet, dass wir potentiell inkompatibel zu alten Monatsabschlüssen werden
         und dementsprechend fallbacks einbauen müssen!
        */
        this.monthData.user = {
          firstname: staticUserData.user.firstname,
          lastname: staticUserData.user.lastname,
          email: staticUserData.user.email,
          mocoUserID: staticUserData.user.id,
          teamName: staticUserData.user.unit.name,
          monthClosingResponsible: staticUserData.user.masterData.monthClosingResponsible,
          userEmployments: staticUserData.userEmployments,
        };
        this.monthData.closing.timestamp = dayjs().format();

        const monthDataClosing = _.cloneDeep(this.monthData);

        // Apply all rules with no disabled ones
        this.applyRules(monthDataClosing, staticUserData.userEmployments == undefined, {}, true); // TODO wieso können die userEmployment undefined sein? Und wieso wird das gehandelt und wirft keinen fehler?

        return monthDataClosing;
      },

      // hauptsächlich wegen Mocking
      loadMonthFromJiraTicket(jiraTicket) {
        const monthData = jiraTicket.month;

        // das ist noch immer notwendig. wie wir am Client die errors/warnings filtern müssen wir noch besprechen
        const skipPresenceRelatedRules = monthData.user.userEmployments == undefined;
        if (this.$store.getters.enabledRules.apply_month_closing) {
          this.applyRules(monthData, skipPresenceRelatedRules, this.$store.getters.enabledRules, true);
        } else {
          this.applyRules(monthData, skipPresenceRelatedRules, {}, true);
        }

        this.monthData = monthData;
        this.attachments = jiraTicket.attachments;
      },
      
      applyRules(monthData, skipPresenceRelatedRules = false, enabledRules, monatsabschluss) {
        monthData.weeks.forEach(week => {
          week.days.forEach(day => {
            statusRules.applyDayRules(day, skipPresenceRelatedRules, enabledRules, monatsabschluss);
          });
          week.status = statusCalculator.calcStatus(week.days);
        });
        
        statusRules.applyMonthRules(monthData, enabledRules, monatsabschluss);
      },

      // hauptsächlich wegen Mocking
      async confirmMonthClosing(showComment) {
        const message = this.monthData.status == 'ok' ? '' :
          'Der Monat hat noch Warnungen oder Fehler, soll der Monatsabschluss dennoch gestartet werden?';
        return await this.$refs.confMonatsabschluss.open({title: 'Monatsabschluss starten?', message}, {confirmText: 'JA', cancelText: 'NEIN'}, {showComment});
      },

      submitMonthWithComment() {
        this._submitMonth(true);
      },

      submitMonth() {
        this._submitMonth(false);
      },

      async _submitMonth(showComment) {
        const confirmation = await this.confirmMonthClosing(showComment);
        
        if (confirmation.confirmed) {
          this.loading = true;
        
          await this.$draf(async () => {
            try {
              await monatsabschlussClient.createMonatsabschluss(this.displayedDate, confirmation.comment, this.buildPayload());
            } catch (err) {
              this.handleError(err);
            }
          });

          this.loading = false;

          this.$root.$emit('reloadApp');
        }
      },

      handleError(err) {
        if (err.response.status == 422 && err.response.data.includes('Monatsabschluss')) {
          this.$store.dispatch('setError', 'Bitte stoppe den Timer bevor du den Monatsabschluss machst!');
        } else {
          this.$store.dispatch('setFatalError', err.response.data);
        }
      }
    },

    computed: {
      monthSubmitable: function () {
        return this.monthData.closing?.status === global.monthClosing.status.open;
      },

      monthFixable: function () {
        return this.monthData.closing?.status === global.monthClosing.status.rejected;
      },

      showGotoTicket: function () {
        return this.monthData.closing?.jiraKey != undefined;
      },
      
      monthApproveableTL: function () {
        const staticUserData = this.$store.getters.staticUserData;
        return this.monthData.closing?.status === global.monthClosing.status.waitingForTL &&
          staticUserData.user.email == this.monthData.user?.monthClosingResponsible;
      },

      monthApproveableHR: function () {
        const staticUserData = this.$store.getters.staticUserData;
        return this.monthData.closing?.status === global.monthClosing.status.waitingForHR &&
          staticUserData.user.roles.isHR;
      },

      backToTLable: function () {
        const staticUserData = this.$store.getters.staticUserData;
        return this.monthData.closing?.status === global.monthClosing.status.waitingForHR &&
          staticUserData.user.roles.isHR;
      },

      monthRejectable: function () {
        const staticUserData = this.$store.getters.staticUserData;

        return (
          (this.monthData.closing?.status === global.monthClosing.status.waitingForTL && (
            (staticUserData.user.email === this.monthData.user?.email) ||
            (staticUserData.user.email === this.monthData.user?.monthClosingResponsible)
          )) ||
          (this.monthData.closing?.status === global.monthClosing.status.waitingForHR && staticUserData.user.roles.isHR)
        );
      },
    },
  };
</script>