import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/qa`);

const deleteMonatsabschluss = async function (jiraKey, reporterEmail, month, year) {
  const config = {
    params: {
      reporterEmail: reporterEmail,
      month: month,
      year: year
    }
  };

  await axiosInstance.delete(`/monatsabschluss${jiraKey ? '/' + jiraKey : ''}`, config);
}

const saveRoles = async function(roles) {
  await axiosInstance.put(`/roles`, roles);
}

export {
  deleteMonatsabschluss,
  saveRoles,
}