import dayjs from '@util/dayjs';
import * as mocoClient from '@client/client.moco';


const DAYJS_SATURDAY = 6;
const DAYJS_SUNDAY = 0;

const _isBookable = function (date, publicHolidays, ignorePublicHolidays, ignoreWeekend) {
  if (ignoreWeekend) {
    if (ignorePublicHolidays) {
      return true;
    } else {
      return !publicHolidays.includes(date.format('YYYY-MM-DD'));
    }
  } else if (ignorePublicHolidays) {
    return date.day() != DAYJS_SATURDAY && date.day() != DAYJS_SUNDAY;
  } else {
    return date.day() != DAYJS_SATURDAY && date.day() != DAYJS_SUNDAY && !publicHolidays.includes(date.format('YYYY-MM-DD'));
  }
}

const _getPublicHolidays = async function () {
  const schedules = (await mocoClient.getStaticUserDataFromMoco()).schedules;
  return schedules.filter(schedule => schedule.assignment.name == 'Feiertag').map(schedule => schedule.date);
}

const createDays = async function(startDate, endDate, dayEntryType, {ignorePublicHolidays = false, ignoreWeekend = false, deleteFirst = true} = {}) {
  await createDaysUnitTests(undefined, undefined, startDate, endDate, dayEntryType, {ignorePublicHolidays, ignoreWeekend, deleteFirst});
}

const createDaysUnitTests = async function(userID, apiKey, startDate, endDate, dayEntryType, {ignorePublicHolidays = false, ignoreWeekend = false, deleteFirst = true} = {}) {
  _setLocalStorage(userID, apiKey);
  
  if (deleteFirst) {
    await deleteEntries(startDate, endDate);
  }
  
  const publicHolidays = await _getPublicHolidays();

  for (let day = dayjs(startDate); day.isSameOrBefore(endDate); day = day.add(1, 'days')) {
    if (_isBookable(day, publicHolidays, ignorePublicHolidays, ignoreWeekend)) {
      for (let i = 0; i < dayEntryType.presences.length; i++) {
        await mocoClient.createPresence(day, dayEntryType.presences[i].from, dayEntryType.presences[i].to, dayEntryType.presences[i].isHomeOffice);
      }

      for (const activity of dayEntryType.activities) {
        await mocoClient.createActivity(day, activity.description, activity.project_id, activity.task_id, activity.hours);
      }
    }
  }
  console.log('Added new entries.');
}

const deleteActivities = async function (from, to) {
  const activities = await mocoClient.getActivities(from, to);

  for (const activity of activities) {
    await mocoClient.deleteActivity(activity.id);
  }
}

const deletePresences = async function (from, to) {
  const presences = await mocoClient.getPresences(from, to);
  for (const presence of presences) {
    await mocoClient.deletePresence(presence.id);
  }
}

const deleteEntries = async function (from, to) {
  await deleteEntriesUnitTests(undefined, undefined, from, to);
}

const deleteSchedules = async function (from, to) {
  await deleteSchedulesUnitTests(undefined, undefined, from, to);
}

const deleteEntriesUnitTests = async function (userID, apiKey, from, to) {
  _setLocalStorage(userID, apiKey);

  await deletePresences(from, to);
  await deleteActivities(from, to);

  console.log(`Deleted existing entries.`);
}

const deleteSchedulesUnitTests = async function (userID, apiKey, from, to) {
  _setLocalStorage(userID, apiKey);

  const schedules  = await mocoClient.getSchedules(from, to);
  for (const schedule of schedules) {
    await mocoClient.deleteSchedule(schedule.id);
  }

  console.log(`Deleted existing schedules.`);
}

const _setLocalStorage = function (userID, apiKey) {
  if (global.localStorage == undefined) {
    global.localStorage = {
      getItem(key) {
        if (key == 'moco_user-id') {
          return userID;
        } else if (key == 'moco_api-key') {
          return apiKey;
        }
      }
    }
  }
}

export {
  createDays,
  createDaysUnitTests,
  deleteEntries,
  deleteEntriesUnitTests,
  deleteSchedules,
  deleteSchedulesUnitTests
}