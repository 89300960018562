import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig' 
import FormData from 'form-data';

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/projektabschluss`);


const createProjektabschluss = async function (date, projectName, projectIdentifier) {
  const data = {
    projectName: projectName,
    projectIdentifier: projectIdentifier,
    dateOfMonth: date.format('YYYY-MM-DD')
  };

  return (await axiosInstance.post('/start', data)).data;
}

const getProjektabschluss = async function (jiraKey, month, year, projectIdentifier) {
  const config = {
    params: {
      projectIdentifier: projectIdentifier,
      month: month,
      year: year,
    }
  };

  return (await axiosInstance.get(`${jiraKey ? '/' + jiraKey : ''}`, config)).data;
}

const transition = async function (jiraKey, transition) {
  const data = {
    transition: transition
  };

  await axiosInstance.put(`/${jiraKey}/transition`, data);
};

const addAttachments = async function (dateOfMonth, projectName, projectIdentifier, attachments) {
  const formData = new FormData();
  formData.append('dateOfMonth', dateOfMonth.format('YYYY-MM-DD'));
  formData.append('projectName', projectName);
  formData.append('projectIdentifier', projectIdentifier);
 
  attachments.forEach((attachment) => {
    formData.append('attachments', attachment);
  });

  try {
    return (await axiosInstance.put('/attachments', formData, {
      headers: {
        'content-Type': 'multipart/form-data'
      }
    })).data;
  } catch {
    return undefined;
  }
}

const deleteAttachment = async function (dateOfMonth, projectIdentifier, attachmentID) {
  const config = {
    params: {
      dateOfMonth: dateOfMonth.format('YYYY-MM-DD'),
      projectIdentifier: projectIdentifier,
      attachmentID: attachmentID
    }
  };
  
  try {
    return (await axiosInstance.delete('/attachment', config)).data; // liefert "" zurück
  } catch {
    return undefined;
  }
}

const getAttachment = async function (url) {
  const config = {
    params: {
      url: url
    },
    responseType: 'arraybuffer'
  };

  try {
    return (await axiosInstance.get('/attachment', config)).data;
  } catch {
    return undefined;
  }
}

const clearCache = async function () {
  await axiosInstance.cache.clear();
}

const clearCacheSpecificRoute = async function (route) {
  await axiosInstance.cache.removeItem(`${axiosInstance.defaults.baseURL}${route}`);
}



export {
  createProjektabschluss,
  getProjektabschluss,
  transition,
  addAttachments,
  deleteAttachment,
  getAttachment,
  clearCache,
  clearCacheSpecificRoute
}