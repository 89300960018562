import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/teamlead`);

const clearCache = async function () {
  await axiosInstance.cache.clear();
}

const getTeamData = async function (year, month) { 
  return (await axiosInstance.get(`/overview/${year}/${month}`)).data;
}

export {
  getTeamData,
  clearCache
}