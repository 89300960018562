<!--https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d-->
<template>
  <v-dialog v-model="visible" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" persistent>
    <v-card>
      <v-card-title data-cy="titleConfirm" class="pl-4">
        {{ this.title }}
      </v-card-title>

      <v-card-text data-cy="messageConfirm" class="pl-4 pr-4 pb-0">
        <div v-if="this.message != undefined">{{ this.message }}</div>
        <div v-if="this.details != undefined">{{ this.details }}</div>

        <v-textarea data-cy="commentConfirm" v-if="this.options.showComment" class="pt-4" label="Kommentar" v-model="comment"
          outlined rows="2" row-dense row-height="30" auto-grow autofocus></v-textarea>
      </v-card-text>

      <v-card-actions :class="this.options.showComment ? 'pt-0' : 'pt-2'">
        <v-spacer></v-spacer>
        <v-btn v-if="this.buttons.cancel" type="button" @click.native="cancel()" color="#c00000" outlined data-cy="cancel">{{ this.buttons.cancel }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="this.buttons.confirm" type="button" @click.native="confirm()" color="#c00000" class="white--text" data-cy="confirm">{{ this.buttons.confirm }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  // https://vuetifyjs.com/en/components/text-fields/#custom-colors -> default mit Object.freeze und Object.assign
  data: () => ({
      visible: false,
      title: null,
      message: null,
      details: null,
      comment: null,
      buttons: {
        confirm: 'OK',
        cancel: 'Abbrechen'
      },
      result: {
        resolve: null,
        reject: null,
      },
      options: {
        width: 420,
        zIndex: 200,
        showComment: false
      }
  }),
  methods: {
    open({title, message, details}, {confirmText, cancelText}, options) {
      this.visible = true;
      this.title = title;
      this.message = message;
      this.details = details;
      this.comment = undefined;
      this.buttons.confirm = confirmText;
      this.buttons.cancel = cancelText;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.result.resolve = resolve;
        this.result.reject = reject;
      })
    },

    confirm() {
      this.result.resolve({
        confirmed: true,
        comment: this.comment
      });
      this.visible = false;
    },
    
    cancel() {
      this.result.resolve({
        confirmed: false
      });
      this.visible = false;
    }
  }
}
</script>