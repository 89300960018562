<template>
  <v-row no-gutters class="d-flex flex-column flex-sm-row justify-end">
    <v-col sm="3" cols="auto" class="d-flex justify-end">
      <v-card class="pa-2 pl-0 text-right d-flex flex-column" flat color="transparent">
        <label id="expectedHoursHeader" class="grey--text overline">Soll</label>
        <label id="expectedHours">{{displayData.hours.expected}}</label>
      </v-card>
    </v-col>
    <v-col sm="3" cols="auto" class="d-flex justify-end">
      <v-card
        id="presenceDetails"
        class="pa-2 pl-0 text-right"
        :hidden="visibility.hideHoursWorked"
        flat
        color="transparent"
      >
        <div class="d-flex flex-column">
          <label id="presenceHoursHeader" class="grey--text overline">Haben</label>
          <label id="presenceHours">{{displayData.hours.presences}}</label>
        </div>
      </v-card>
    </v-col>
    <v-col sm="3" cols="auto" class="d-flex justify-end">
      <v-card
        id="presenceDiffDetails"
        class="pa-2 pl-0 text-right"
        :hidden="visibility.hideHoursWorked"
        flat
        color="transparent"
      >
        <div class="d-flex flex-column">
          <label id="presenceDiffHeader" class="grey--text overline">Diff</label>
          <label id="presenceDiff">{{makeNumberSigned(displayData.hours.presenceDiff)}}</label>
        </div>
      </v-card>
    </v-col>
    <!--<v-col sm="3" cols="auto" class="d-flex justify-end">
      <v-card
        id="activityDetails"
        class="pa-2 pl-0 text-right"
        :hidden="visibility.hideHoursWorked"
        flat
        color="transparent"
      >
        <div class="d-flex flex-column">
          <label id="activityHoursHeader" class="grey--text overline">Leistungszeit</label>
          <label id="activityHours">{{displayData.hours.activities}}</label>
        </div>
      </v-card>
    </v-col>-->
    <v-col sm="3" cols="auto" class="d-flex justify-end">
      <v-card class="pa-2 pl-0 text-right d-flex flex-column" flat color="transparent">
        <label id="flexTimeBalanceHeader" class="grey--text overline">Gleitzeit</label>
        <label id="flexTimeBalance">
          <label
            v-if="hours < 0"
            class="red--text"
          >{{formattedHours}}</label>
          <label v-else class="green--text">
            {{formattedHours}}
          </label>
        </label>
      </v-card>
    </v-col>
  </v-row>
</template>


<script lang='js'>
  import StatusColorCalculator from '@/mixins/StatusColorCalculator.vue';

  export default {
    name: 'HoursSummary',
    props: ['displayData', 'visibility', 'extrapolateFlexTime'],
    mixins: [StatusColorCalculator],
    data() {
      return {
        // extrapolated: false,
      }
    },
    created() {
      // this.extrapolated = this.extrapolateFlexTime && this.displayData.hours.extrapolated;
    },
    computed: {
      extrapolated() {
        return this.extrapolateFlexTime && this.displayData.hours.extrapolated;
      },
      hours() {
        return this.extrapolated ? this.displayData.hours.flexTimeBalanceExtrapolated : this.displayData.hours.flexTimeBalance;
      },
      formattedHours() {
        if (this.extrapolated) {
          return `(${this.makeNumberSigned(this.hours)})`;
        } else {
          return this.makeNumberSigned(this.hours);
        }
      }
    }
  }
</script>