<!--https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d-->
<template>
  <v-dialog v-model="visible" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" persistent>
    <v-card>
      <v-card-title class="pl-4">
        {{ this.title }}
      </v-card-title>

      <v-card-text class="pl-4 pr-4 pb-0">
        <div v-if="this.message != undefined">{{ this.message }}</div>
        <div v-if="this.details != undefined">{{ this.details }}</div>
      </v-card-text>

      <v-card-actions class="pt-2">
        <v-spacer></v-spacer>
        <v-btn v-if="cancelButtonText" type="button" @click.native="cancel()" color="#c00000" outlined data-cy="cancel">{{ cancelButtonText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="confirmButtonText" type="button" @click.native="confirm()" color="#c00000" class="white--text" data-cy="confirm">{{ confirmButtonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',
  props: {
    confirmButtonText: {
      type: String,
      default: "Ja"
    },
    cancelButtonText: {
      type: String,
      default: "Abbrechen"
    }
  },
  data: () => ({
    visible: false,
    message: null,
    details: null,
    title: null,
    result: {
      resolve: null,
      reject: null,
    },
    options: {
      width: 420,
      zIndex: 200
    }
  }),
  methods: {
    open(title, message, details, options) {
      this.visible = true;
      this.title = title;
      this.message = message;
      this.details = details;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.result.resolve = resolve;
        this.result.reject = reject;
      })
    },

    cancel() {
      this.result.resolve(false);
      this.visible = false;
    },
    
    confirm() {
      this.result.resolve(true);
      this.visible = false;
    }
  }
}
</script>