import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'

const axiosInstance = sqtAxiosConfig.getNewInstance(`${global.SEQITRACKER_API_URL}/admin`, { exclude: { paths: ['/cache/.*'] } });


const refreshCache = async function () {
  await axiosInstance.post('/cache/refresh');
}

const getMocoUsers = async function () {
  return (await axiosInstance.get('/cache/moco/users')).data;
}

const getMocoProjects = async function () {
  return (await axiosInstance.get('/cache/moco/projects')).data;
}

const getJiraUsers = async function () {
  return (await axiosInstance.get('/cache/jira/users')).data;
}

const createPublicHolidays = async function (year) {
  await axiosInstance.post('/createPublicHolidays', { year: year });
}

const testMail = async function () {
  await axiosInstance.get('/test-mail');
}

export {
  testMail,
  createPublicHolidays,
  refreshCache,
  getMocoUsers,
  getMocoProjects,
  getJiraUsers
}