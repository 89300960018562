import * as mocoClient from '@client/client.moco';
import * as mocoTestDataClient from './MocoTestDataClient';
import * as testDataPresets from './testDataPresets.js';


const deleteAllEntries = async function(startOfMonth, endOfMonth, logContainer) {
  // error  Possible race condition: `logContainer.log` might be reassigned based on an outdated value of `logContainer.log`  require-atomic-updates
  /* eslint-disable require-atomic-updates */
  if (logContainer) {
    logContainer.log += 'delete all activities and presences\r\n';
  }
  await mocoTestDataClient.deleteEntries(startOfMonth, endOfMonth);
  if (logContainer) {
    logContainer.log += 'delete all activities and presences: DONE\r\n';
    logContainer.log += 'delete all schedules\r\n';
  }
  await mocoTestDataClient.deleteSchedules(startOfMonth, endOfMonth);
  if (logContainer) {
    logContainer.log += 'delete all schedules: DONE\r\n';
  }
}

const createDays = async function(startDate, endDate, dayEntryType, options, logContainer) {
  const filledDays = await getAlreadyFilledDays(startDate, endDate);
  for (let dateIterator = startDate; dateIterator.isSameOrBefore(endDate); dateIterator = dateIterator.add(1, 'days')) {
    if (filledDays.includes(dateIterator.format('YYYY-MM-DD')) == false) {
      await mocoTestDataClient.createDays(dateIterator, dateIterator, dayEntryType, options);
      if (logContainer) {
        logContainer.log += `day created at ${dateIterator.format('DD.MM.YYYY')}\r\n`;
      }
    }
  }
};

const createTestMonth = async function(startOfMonth, endOfMonth, logContainer) {
  let dateIterator = getNextWorkingDay(startOfMonth.subtract(1, 'days'), endOfMonth);
  await mocoClient.createHoliday(dateIterator);
  await createDays(dateIterator, dateIterator, testDataPresets.holiday, {ignoreWeekend: false, deleteFirst: false}, logContainer);

  let days = [
    testDataPresets.empty, testDataPresets.empty, testDataPresets.noBreak, testDataPresets.tooShortBreak, testDataPresets.timeDifferences, testDataPresets.over12h, 
    testDataPresets.holiday, testDataPresets.absencesAloneExceedLimit, testDataPresets.absencesExceedLimit, testDataPresets.over10h, testDataPresets.activityWith0Duration,
    testDataPresets.activityWithoutDescription, testDataPresets.longerBreak, testDataPresets.presenceAndActivityNotRoundedToFifteenMinutes, testDataPresets.presenceNotRoundedToFifteenMinutes
  ]

  for (let dayEntryType of days) {
    await createDays(dateIterator, dateIterator, dayEntryType, {ignoreWeekend: false, deleteFirst: false}, logContainer);
    dateIterator = getNextWorkingDay(dateIterator, endOfMonth);
  }

  dateIterator = getNextWorkingDay(dateIterator.subtract(1, 'days'), endOfMonth);
  await mocoClient.createPublicHoliday(dateIterator);

  days = [testDataPresets.empty, testDataPresets.homeOffice, testDataPresets.standardDay]
  for (let dayEntryType of days) {
    await createDays(dateIterator, dateIterator, dayEntryType, {ignoreWeekend: false, deleteFirst: false}, logContainer);
    dateIterator = getNextWorkingDay(dateIterator, endOfMonth);
  }
  
  dateIterator = getPrevWorkingDay(endOfMonth.add(1, 'days'), startOfMonth);
  await mocoClient.createHoliday(dateIterator);
  await createDays(dateIterator, dateIterator, testDataPresets.holiday, {ignoreWeekend: false, deleteFirst: false}, logContainer);

  const weekend = getNextWeekend(startOfMonth.subtract(1, 'days'), endOfMonth);
  await createDays(weekend, weekend, testDataPresets.standardDay, {ignoreWeekend: true, deleteFirst: false}, logContainer);
};

const createRegressionTestDays = async function (startOfMonth) {
  let dateIterator = startOfMonth.subtract(1, 'days');
  for (let i = 0; i < 3; i++) {
    dateIterator = getNextWorkingDay(dateIterator);
    await mocoTestDataClient.createDays(dateIterator, dateIterator, testDataPresets.standardDay, { ignorePublicHolidays: true });
  }
}

const createSettingRegressionTestDays = async function (startDate, testDays) {
  let dateIterator = startDate;
  
  for (let dayEntryType of testDays) {
    await createDays(dateIterator, dateIterator, dayEntryType, {ignoreWeekend: true, deleteFirst: false});
    dateIterator = getNextWorkingDay(dateIterator, startDate.endOf('month'));
  }
}

const getAlreadyFilledDays = async function(startOfMonth, endOfMonth) {
  const filledDays = []
  const activities = await mocoClient.getActivities(startOfMonth, endOfMonth);
  activities.forEach(activity => filledDays.push(activity.date));
  const presences = await mocoClient.getPresences(startOfMonth, endOfMonth);
  presences.forEach(presence => filledDays.push(presence.date));
  return filledDays;
};

const getNextWorkingDay = function(date, endDate) {
  for (let dateIterator = date.add(1, 'days'); endDate != undefined ? dateIterator.isSameOrBefore(endDate) : true; dateIterator = dateIterator.add(1, 'days')) {
    if (dateIterator.day() > 0 && dateIterator.day() < 6) {
      return dateIterator;
    }
  }
};

const getNextWeekend = function(date, endDate) {
  for (let dateIterator = date.add(1, 'days'); endDate != undefined ? dateIterator.isSameOrBefore(endDate) : true; dateIterator = dateIterator.add(1, 'days')) {
    if (dateIterator.day() == 0 || dateIterator.day() == 6) {
      return dateIterator;
    }
  }
};

const getPrevWorkingDay = function(date, startDate) {
  for (let dateIterator = date.subtract(1, 'days'); startDate != undefined ? dateIterator.isSameOrAfter(startDate) : true; dateIterator = dateIterator.subtract(1, 'days')) {
    if (dateIterator.day() > 0 && dateIterator.day() < 6) {
      return dateIterator;
    }
  }
};

export {
  deleteAllEntries,
  createDays,
  createTestMonth,
  createRegressionTestDays,
  createSettingRegressionTestDays
}