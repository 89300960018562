import dayjs from 'dayjs';

import objectSupport from 'dayjs/plugin/objectSupport'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isoStandards from 'seqitracker-util/lib/dayjs/plugins/isoStandards'
import isoWeek from 'dayjs/plugin/isoWeek'
import 'dayjs/locale/de-at'

dayjs.locale('de-at')

// dayjs.extend(DayjsPlugin);
dayjs.extend(isoStandards);
dayjs.extend(objectSupport);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

export default dayjs