import * as sqtAxiosConfig from '@client/axios/sqt.axiosConfig'

const axiosInstance = sqtAxiosConfig.getNewInstance(global.SEQITRACKER_API_URL, { exclude: { methods: ['patch', 'put', 'delete'] } });


const getVersion = async function () {
  return (await axiosInstance.get(`/version`)).data;
}

const getMocoUserData = async function () {
  return (await axiosInstance.get('/user/userdata')).data;
}

const getMocoProjectLeadActivities = async function (mocoProjectID, fromDate, toDate) {
  const config = {
    params: {
      projectID: mocoProjectID,
      from: fromDate.format('YYYY-MM-DD'),
      to: toDate.format('YYYY-MM-DD')
    }
  }

  return (await axiosInstance.get('/user/projectLeadActivities', config)).data;
}

const getProjectMemberStatuses = async function (projectID, month, year) {
  const config = {
    params: {
      projectID: projectID,
      month: month,
      year: year
    }
  }

  return (await axiosInstance.get('/user/projectMemberStatuses', config)).data;
}

const getMonatsdaten = async function (month, year, activities, presences, userEmployments, userHolidays, schedules, enabledRules) {
  const data = {
    activities: activities,
    presences: presences,
    userEmployments: userEmployments,
    userHolidays: userHolidays,
    schedules: schedules,
    enabledRules: enabledRules
  };

  try {
    return (await axiosInstance.post(`/monthdata/month/${year}/${month}`, data)).data.month;
  } catch (e) {
    return undefined;
  }
}

const clearCache = async function () {
  await axiosInstance.cache.clear();
}

export {
  getVersion,
  getMonatsdaten,
  getMocoUserData,
  getMocoProjectLeadActivities,
  getProjectMemberStatuses,
  clearCache
}