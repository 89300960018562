import { setupCache as _setupCache } from 'axios-cache-adapter';
import axios from 'axios';
import { axiosInterceptors } from 'seqitracker-util';

const setupCache = function(customCacheOptions = {}) {
  const cacheOptions = {
    ... {
      maxAge: customCacheOptions.maxAge ?? 30 * 60 * 1000,
      debug: customCacheOptions.debug ?? false,
      // eslint-disable-next-line no-unused-vars
      invalidate: customCacheOptions.invalidate ?? (async (cfg, req) => {
        if (global.impersonationID) {
          await cfg.store.removeItem(cfg.uuid); // disable caching wenn impersonated
        }
      }),
      exclude: { 
        query: customCacheOptions.exclude?.query ?? false,
        paths: customCacheOptions.exclude?.paths ?? [],
        methods: customCacheOptions.exclude?.methods ?? ['post', 'patch', 'put', 'delete']
      }
    }
  }
  return _setupCache(cacheOptions);
}


const getNewInstance = function(baseURL, adapter, cache) {
  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      accept: 'application/json'
    },
    adapter
  });

  addDefaultInterceptor(axiosInstance);

  if (cache) {
    axiosInstance.cache = cache.store;
  }
  return axiosInstance;
}

const addDefaultInterceptor = function(axiosInstance) {

  const isRetryable = function (error) {
    // sometimes HTTP 429 is handled as an error without response ...
    const retryNoResponseObject = error.response == undefined && error.message == 'Network Error' 
      && (error.config?.baseURL.includes(global.MOCO_SERVER_URL) || error.config?.baseURL.includes(global.SEQITRACKER_SERVER_URL));
    // sometimes its a simple response error with status 429
    const retryWithResponseObject = error.response?.status === 429;
    return !!(retryNoResponseObject || retryWithResponseObject);
  }

  const logger = global.isE2ETest || global.isDevelopment ?  {
    debug: () => {}, // debug: (msg, params) => console.debug(msg, params),
    info: () => {}, // info: (msg, params) => console.info(msg, params),
    warning: (msg, params) => console.warn(msg, params),
    error: (msg, params) => console.error(msg, params),
  } : {
    debug: () => {},
    info: () => {},
    warning: (msg, params) => console.warn(msg, params),
    error: (msg, params) => console.error(msg, params),
  };
  
  axiosInstance.interceptors.request.use(axiosInterceptors.logging.request(logger), axiosInterceptors.logging.error(logger));
  axiosInstance.interceptors.response.use(axiosInterceptors.logging.response(logger), axiosInterceptors.logging.error(logger));
  axiosInstance.interceptors.response.use(null, axiosInterceptors.retryAfter.error(axiosInstance, logger, { isRetryable, defaultWait: 30 }));
  axiosInstance.interceptors.response.use(axiosInterceptors.pagination.response(axiosInstance), null);
}

export {
  setupCache,
  getNewInstance,
  addDefaultInterceptor
}