<template>
  <v-card flat class="d-flex flex-column ma-0 pa-0 flex-grow-1">
    <v-divider v-if="fileManipulationEnabled"></v-divider>
    <v-row no-gutters>
      <v-col v-if="fileManipulationEnabled" cols="4" class="ma-3 mr-2 ml-0">
        <!--
          @dragover.prevent & @dragleave.prevent überschreiben das Standard-DOM-Event-Verhalten beim rüberziehen von Dateien mit starten/beenden einer Animation.
          Durch @drop.prevent="dropFiles($event)" reagiert das Feld aufs "droppen" von "gedraggten" Files mit der Methode dropFiles($event)
        -->
        <v-card style="border-radius: 15px;" flat height="300">
          <div id="dragAndDropArea" flex ref="dragAndDropArea" class="file-drop-area fill-height text-center pa-4"
            @dragover.prevent="$refs.dragAndDropArea.classList.add('dragged-over')"
            @dragleave.prevent="$refs.dragAndDropArea.classList.remove('dragged-over')"
            @drop.prevent="dropFiles($event)"
            @click="$refs.fileInput.$refs.input.click()">
            <v-icon style="opacity: 0.8;" size="100">mdi-cloud-upload</v-icon>
            <span>Ziehe Dateien hierhin oder klicke zum Auswählen</span>
          </div>
        </v-card>
      </v-col>

      <v-divider v-if="fileManipulationEnabled" class="my-3 mx-1" vertical></v-divider>

      <v-col>
        <v-fade-transition v-if="fileUploadLoading" mode="out-in">
          <v-container style="height: 300px;">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col cols="6">
                <v-progress-linear color="#c00000" indeterminate rounded height="6"></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-fade-transition>
        <v-fade-transition v-else-if="sortedAttachments && sortedAttachments.length > 0" mode="in-out">
          <v-card flat class="overflow-y-auto custom-scrollbar" fill-height>
            <v-list id="attachmetList" class="pa-0 ma-0 mb-2">
              <v-row class="mx-2 mt-2">
                <v-col class="ma-0 pa-1" v-for="(attachment, index) in sortedAttachments" :key="index"
                  :xl="sortedAttachments.length > 1 ? 6 : 12" md="12" s="12">
                  <v-card>
                    <v-list-item :id="index" class="my-0 py-0">
                      <template>
                        <v-list-item-content class="py-0 ">
                          <v-row>
                            <v-col cols="auto" class="ma-0 pa-0 mt-4" no-gutters>
                              <v-icon class="ml-2" size="30">{{ getFileExtensionIcon(attachment.filename) }}</v-icon>
                            </v-col>
                            <v-col class="text-start">
                              <v-list-item-subtitle>
                                <b>{{ attachment.filename }}</b>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle wrap class="mt-1">
                                {{ formatDate(attachment.created) }}&nbsp; <b>•</b> &nbsp;{{ attachment.humanReadableSize }}
                              </v-list-item-subtitle>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row no-gutters>
                            <v-col cols="6">
                              <v-btn  class="mx-0" icon :loading="fileDownloadLoadingIDs.includes(attachment.id)" @click="downloadAttachment(attachment)">
                                <v-icon class="mt-1">mdi-tray-arrow-down</v-icon>
                              </v-btn>
                            </v-col>

                            <v-col v-if="fileManipulationEnabled" cols="6"> 
                              <v-btn class="mx-0" icon :loading="fileDeleteLoadingIDs.includes(attachment.id)" @click="deleteAttachment(attachment)">
                                <v-icon>mdi-delete </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-list>
          </v-card>
        </v-fade-transition>
        <v-fade-transition v-else mode="out-in">
          <v-card flat class="d-flex align-center justify-center" :height="fileManipulationEnabled ? 300 : ''">
            <p v-if="fileManipulationEnabled" id="noFilesAttachedMessage" class="align-self-center mt-4">Es wurden keine Dateien hochgeladen.</p>
            <p v-else id="fileManipulationDisabledMessage" class="align-self-center mt-4">Der Dateiupload ist deaktiviert, mögliche Ursache(n): aktive Impersonation, zukünftiges Monat, Status Monatsabschluss, ...</p>
          </v-card>
        </v-fade-transition>
      </v-col>
    </v-row>

    <v-divider v-if="fileManipulationEnabled"></v-divider>

    <div v-if="fileManipulationEnabled">
      <v-card-actions class="mt-2 pa-0">
        <v-file-input  clear-icon="mdi-close-circle" ref="fileInput" id="fileInput"
          color="#232323" v-model="filesSelected" :rules="[fileSizeBelowLimit]" multiple clearable
          :label="`Dokumente (maximal ${formatBytes(global.maxFileSize, 0)} pro Datei und insgesamt nicht mehr als ${formatBytes(global.maxTotalFileSize, 0)})`" class="mr-5 mt-2" @click="fileInputClicked()"
          @change="onSelectedFileChange()">
          <template v-slot:selection="{ index, file }">
            <v-chip small close @click:close="removeSelectedFile(index)">
              {{ file.name }} &nbsp;
              <h5 :style="'color: ' + (file.size > global.maxFileSize ? 'red' : '#6B6B6B') + ';'">
                {{ formatBytes(file.size) }}
              </h5>
            </v-chip>
          </template>
        </v-file-input>
        <v-btn id="uploadButton" :disabled="this.filesSelected.length == 0 || fileSizeBelowLimit == false"
          color="#c00000" class="white--text" :loading="this.fileUploadLoading" @click="addAttachments()">
          Upload
          <v-icon right dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script lang="js">

import dayjs from '@util/dayjs';
export default {
  name: 'FileManager',
  props: ['attachments', 'fileManipulationEnabled', 'fileUploadLoading', 'fileDownloadLoadingIDs', 'fileDeleteLoadingIDs'],

  data() {
    return {
      filesAlreadySelected: [],
      filesSelected: []
    }
  },

  computed: {
    sortedAttachments() {
      return [...this.attachments ?? []].sort((a, b) => a.filename.toLowerCase() > b.filename.toLowerCase() ? 1 : -1);
    },

    fileSizeBelowLimit() {
      // die rule für v-*-input "rule" muss im fehlerfall "false" zurückliefern: https://v2.vuetifyjs.com/en/api/v-input/#props
      const allFilesBelowLimit = this.filesSelected?.some(file => file.size > global.maxFileSize) ? false : true;
      const sumBelowTotalLimit = this.filesSelected?.reduce((accumulator, file) => accumulator + file.size, 0) <= global.maxTotalFileSize;
      return allFilesBelowLimit && sumBelowTotalLimit;
    }
  },

  methods: {
    // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
    formatBytes(bytes, decimals = 2) {
      if (bytes == 0) {
        return '0 Bytes';
      }

      const kilobyte = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const sizeIndex = Math.floor(Math.log(bytes) / Math.log(kilobyte));
      return parseFloat((bytes / Math.pow(kilobyte, sizeIndex)).toFixed(decimals ?? 2)) + ' ' + sizes[sizeIndex];
    },

    formatDate(date) {
      return dayjs(date).format('DD. MMM YYYY HH:mm:ss');
    },

    fileInputClicked() {
      this.filesAlreadySelected = [];
      this.filesAlreadySelected.push(...this.filesSelected);
    },

    onSelectedFileChange() {
      this.filesAlreadySelected.push(...this.filesSelected);
      this.filesSelected.splice(0); // Array leeren ohne Referenz neuzusetzen. Sonst wird ein neues @change Event getriggert
      this.filesSelected.push(...this.filesAlreadySelected);
      this.filesAlreadySelected = [];
    },

    // TODO das Verhalten im Safari ist komisch
    dropFiles(event) {
      this.filesSelected.push(...event.dataTransfer.files);
      this.$refs.dragAndDropArea.classList.remove('dragged-over') // Da nach einem "drop" kein "dragleave" stattfindet, muss hier nach dem drop die animation extra resetet werden
    },

    downloadAttachment(file) {
      this.$emit('downloadAttachment', file);
    },

    deleteAttachment(attachment) {
      this.$emit('deleteAttachment', attachment);
    },

    removeSelectedFile(index) {
      this.filesSelected.splice(index, 1);
    },

    addAttachments() {
      this.$emit('addAttachments', this.filesSelected);
      this.filesSelected = [];
    },

    getFileExtensionIcon(fileName) {
      const fileExtension = fileName.split('.').pop();
      const mdiIcons = {
        doc: 'mdi-file-word-outline',
        docx: 'mdi-file-word-outline',
        ppt: 'mdi-file-powerpoint-outline',
        pptx: 'mdi-file-powerpoint-outline',
        xls: 'mdi-file-excel-outline',
        xlsx: 'mdi-file-excel-outline',
        pdf: 'mdi-file-pdf-box',
        txt: 'mdi-file-document-outline',
        jpg: 'mdi-file-image-outline',
        jpeg: 'mdi-file-image-outline',
        png: 'mdi-file-image-outline',
        gif: 'mdi-file-image-outline',
        mp3: 'mdi-file-music-outline',
        mp4: 'mdi-file-video-outline',
        avi: 'mdi-file-video-outline',
        mkv: 'mdi-file-video-outline',
      };
      return mdiIcons[fileExtension] ?? 'mdi-file-outline';
    }
  }
}
</script> 

<style type="scss">
.file-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px dashed #ccc;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), border-color 0.1s ease;
  transform: scale(1);
}

.file-drop-area:hover,
.file-drop-area.dragged-over {
  border-color: #5b5b5b;
  transform: scale(1.01);
}

.file-drop-area input {
  display: none;
}

.file-drop-area v-icon {
  font-size: 48px;
}

.file-drop-area span {
  font-size: 16px;
  margin-top: 10px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.15em;
  background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #000000;
}
</style>