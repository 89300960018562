import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from '@util/dayjs';

import * as sqtClient from '@client/client.sqt';
import * as mocoClient from '@client/client.moco';
import persistentStore from '@store/persistentStore';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: undefined,
    confirmations: {},
    staticUserData: {
      user: undefined,
      userEmployments: undefined,
      userHolidays: undefined,
      schedules: undefined
    },
    userAuthenticationData: {
      authenticated: persistentStore.getAuthentication().mocoApiKey != null && persistentStore.getAuthentication().mocoUserID != null,
      mocoApiKey: persistentStore.getAuthentication().mocoApiKey,
      mocoUserID: persistentStore.getAuthentication().mocoUserID,
      email: persistentStore.getAuthentication().email
    },
    enabledRules: {},
    otherSettings: persistentStore.getItem('other_settings') ?? {},
    displayedMonth: dayjs().date(1),
    prevRoute: global.ROUTES.aktuellermonat.path
  },
  mutations: {
    changeDisplayMonth(state, displayedMonth) {
      state.displayedMonth = displayedMonth.clone();
    },
    setError(state, error) {
      state.error = error;
    },
    setConfirmation(state, { confirmation, status }) {
      const newConfirmation = {};
      newConfirmation[confirmation] = status;
      
      const confirmations = {
        ...state.confirmations,
        ...newConfirmation
      } 
      
      state.confirmations = confirmations;
    },
    setStaticUserData(state, userData) {
      state.staticUserData = userData;
    },
    setImpersonationID(state, impersonationID) {
      state.impersonationID = impersonationID;
    },
    clearStaticUserData(state) {
      state.staticUserData = {
        user: undefined,
        userEmployments: undefined,
        userHolidays: undefined,
        schedules: undefined,
        leadingProjects: undefined,
        bookableProjects: undefined
      };
    },
    setUserAuthenticationData(state, userAuthenticationData) {
      userAuthenticationData.authenticated = true;
      state.userAuthenticationData = userAuthenticationData;
      persistentStore.setAuthentication(userAuthenticationData.email, userAuthenticationData.mocoUserID, userAuthenticationData.mocoApiKey);
    },
    clearUserAuthenticationData(state, keepEmail) {
      state.userAuthenticationData.mocoApiKey = undefined;
      state.userAuthenticationData.mocoUserID = undefined;
      state.userAuthenticationData.authenticated = false;
      if (keepEmail == false) {
        state.userAuthenticationData.email = undefined;
      }
      persistentStore.clearAuthentication(keepEmail);
    },
    setEnabledRules(state, enabledRules) {
      state.enabledRules = enabledRules;
    },
    setOtherSettings(state, otherSettings) {
      state.otherSettings = otherSettings;
    },
    setPrevRoute(state, prevRoute) {
      state.prevRoute = prevRoute;
    }
  },
  actions: {
    setError(context, errorMsg) {
      context.commit('setError', { msg: errorMsg, fatal: false });
    },
    setFatalError(context, errorMsg) {
      context.commit('setError', { msg: errorMsg, fatal: true });
    },
    clearError(context) {
      context.commit('setError', undefined);
    },
    setConfirmation(context, { confirmation, status }) {
      context.commit('setConfirmation', { confirmation, status });
    },
    initEnabledRules(context) {
      if (context.state.staticUserData?.user == undefined) {
        return;
      }
      
      let enabledRules;
      
      const availableRules = context.state.staticUserData.user.roles.isGL ? global.availableRules.gl : global.availableRules.user; // wenn der user nicht eingeloggt ist gibts kein staticUserData.user
      const defaultEnabledRules = Object.fromEntries(availableRules.map((rule) => ([rule, true])));
      
      try {
        enabledRules = persistentStore.getItem('enabled_rules');
        if (enabledRules && typeof enabledRules === 'object') {
          const rulesToAdd = availableRules.filter(availableRule => (enabledRules[availableRule] === undefined));
          const rulesToRemove = Object.keys(enabledRules).filter(enabledRule => (defaultEnabledRules[enabledRule] === undefined));
          
          rulesToAdd.forEach(rule => enabledRules[rule] = true);
          rulesToRemove.forEach(rule => delete enabledRules[rule]);
        } else {
          enabledRules = defaultEnabledRules;
        }
      } catch (err) {
        context.dispatch('setError', 'Gespeicherte Einstellungen konnten nicht geladen werden.');
        context.dispatch('setEnabledRules', defaultEnabledRules);
      }

      context.dispatch('setEnabledRules', enabledRules);
    },
    async loadStaticUserData(context, impersonationID) {
      let userData;
      try {
        const staticMocoUserDataPromise = mocoClient.getStaticUserDataFromMoco();
        const userPromise = sqtClient.getMocoUserData();
        const [staticMocoUserData, user] = await Promise.all([staticMocoUserDataPromise, userPromise]);
        
        const leadingProjects = user.leadingProjects;
        const bookableProjects = user.bookableProjects;

        delete user.leadingProjects;
        delete user.bookableProjects;

        userData = {
          user,
          userEmployments: staticMocoUserData.userEmployments,
          userHolidays: staticMocoUserData.userHolidays,
          schedules: staticMocoUserData.schedules,
          publicHolidays: [],
          leadingProjects: leadingProjects,
          bookableProjects: bookableProjects
        };
        userData.schedules.forEach(schedule => {
          if (schedule.assignment.name == 'Feiertag') {
            userData.publicHolidays.push(schedule.date);
          }
        });
      } catch (err) {
        userData = undefined;
        context.dispatch('setFatalError', `Nutzerdaten konnten nicht abgerufen werden. \n\n Error: ${JSON.stringify(err.response?.data)}`);
        context.dispatch('clearUserAuthenticationData', true);
      }
      context.commit('setStaticUserData', userData);
      context.commit('setImpersonationID', impersonationID);
    },
    clearStaticUserData(context) {
      context.commit('clearStaticUserData');
    },
    // TODO Vorbereitung fuer Reload, funktioniert aber nocht nicht
    async reloadStaticUserData(context, impersonationID) {
      // wenn man zuerst "cleared" und dann "loaded" hat man 2x reactivity. also einfach nur "load", weil dann hast nur 1x reactivity
      //await context.dispatch('clearStaticUserData');
      await context.dispatch('loadStaticUserData', impersonationID);
    },
    setUserAuthenticationData(context, userAuthenticationData) {
      context.commit('setUserAuthenticationData', userAuthenticationData);
    },
    clearUserAuthenticationData(context, keepEmail) {
      context.commit('clearUserAuthenticationData', keepEmail);
    },
    setEnabledRules(context, enabledRules) {
      context.commit('setEnabledRules', enabledRules);
      persistentStore.setItem('enabled_rules', enabledRules);
    },
    setOtherSettings(context, otherSettings) {
      context.commit('setOtherSettings', otherSettings);
      persistentStore.setItem('other_settings', otherSettings);
    }
  },
  modules: {},
  getters: { // TODO 'getters' überhaupt notwendig?
    error: (state) => state.error,
    staticUserData: (state) => state.staticUserData,
    userAuthenticationData: (state) => state.userAuthenticationData,
    enabledRules: (state) => state.enabledRules,
    otherSettings: (state) => state.otherSettings
  }
});